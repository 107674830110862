import React from "react";

type Variant = "primary" | "error"

interface ButtonProps {
  title: string | React.ReactNode;
  onClick: () => void;
  outline?: boolean
  disabled?: boolean
  variant?: Variant
}

export const Button: React.FC<ButtonProps> = ({ title, onClick, outline, variant = "primary" }) => {
  const backgroundColorOutline = variant === "primary" ? "border-zimx-indigo-200 text-zimx-indigo-200 border-x-2 border-y-2" : "border-red-400 text-red-400";
  const backgroundColor = variant === "primary" ? "bg-zimx-indigo-200 text-white" : "bg-red-400 text-white";

  return (
    <button
      type="button"
      className={` h-12  w-full rounded-md font-extrabold ${outline ? backgroundColorOutline : backgroundColor}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};
