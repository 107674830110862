/* eslint-disable indent */
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Address, useAddressess } from ".";
import { ScreenContainer } from "../../components/common/screenContainer";
import AddressIllustraction from "../../assets/images/shop/address.webp";
import { Button } from "../../components/common/button/button";
import { fetchFactory, RequestMethods } from "../../redux/actions/fetchFactory";
import { RootState } from "../../redux/store";
import { AddressModal } from "../../components/AddressModal/AddressModal.component";
import { AddressList } from "./AddressList.component";

export const AddressScreen = (): JSX.Element => {
  const [state, setState] = useState<Address>({} as Address);
  const [selectedAddress, setSelectedAddress] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { addresses, loading } = useAddressess();
  const reduxAccountState = useSelector(
    (reduxState: RootState) => reduxState.user,
  );

  const onToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name) {
      setState({ ...state, [name]: value });
    }
  };
  const onSelectAddress = (addressId: string) => {
    if (addressId) {
      setSelectedAddress(addressId);
    }
  };

  const onSelect = (fieldName: string, fieldValue: string) => {
    if (fieldName === "country") {
      setState({
        ...state,
        [fieldName]: fieldValue,
        city: undefined,
        town: undefined,
      });
    } else if (fieldName === "city") {
      setState({
        ...state,
        [fieldName]: fieldValue,
        town: undefined,
      });
    } else {
      setState({
        ...state,
        [fieldName]: fieldValue,
      });
    }
  };

  const checkout = useMutation<{ paymentUrl: string }>(
    async () => {
      if (selectedAddress) {
        return fetchFactory({
          endpoint: `/api/product-orders`,
          method: RequestMethods.POST,
          token: reduxAccountState.token,
          body: {
            deliveryAddress: selectedAddress,
          },
          errorMessage: "Failed to update initiate payment",
        });
      }
      toast.error("Please select/add a delivery address");
    },
    {
      onSuccess: (response) => {
        window.open(response.paymentUrl);
      },
    },
  );

  const MemoizedAddressList = React.memo(() => (
    <AddressList
      addresses={addresses || []}
      onClickAddAddress={onToggle}
      onSelectAddress={onSelectAddress}
      selectedAddressId={selectedAddress}
    >
      <Button onClick={() => checkout.mutate()} title="Proceed to payment" />
    </AddressList>
  ));

  return (
    <ScreenContainer loading={loading}>
      <label className="text-3xl font-bold text-gray-700">
        Delivery Address
      </label>
      <div className="w-full border-b h-2 mb-4" />

      <div className="flex w-full h-full justify-between">
        <div className="md:flex hidden w-1/2 items-center justify-center self-start">
          <img src={AddressIllustraction} className="w-full aspect-auto" alt="" />
        </div>
        <div className="flex  md:w-1/2 w-full sm:bg-transparent md:bg-white rounded-md self-start items-center justify-center flex-col">
          <MemoizedAddressList />
        </div>
      </div>

      <AddressModal
        isOpen={isOpen}
        onChange={onChange}
        address={state}
        onSelect={onSelect}
        onToggle={onToggle}
      />
    </ScreenContainer>
  );
};
