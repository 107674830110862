/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Address } from ".";
import { City } from "../../components/admin/cities/Cities.types";
import { Town } from "../../components/admin/towns/Towns.types";
import { Country } from "../../components/admin/countries/Countries.types";

export const AddressList = ({
  selectedAddressId,
  onSelectAddress,
  addresses,
  onClickAddAddress,
  children,
}: {
  selectedAddressId?: string;
  onSelectAddress: (addressId: string) => void;
  addresses: Address[];
  onClickAddAddress: () => void;
  children: React.ReactNode;
}): JSX.Element => {
  const renderAddresses = (): JSX.Element[] | undefined =>
    addresses?.map?.((address) => (
      <div
        className={`flex w-full justify-between pt-2 pb-2 px-4 border-b ${
          selectedAddressId === address.id
            ? "bg-zimx-indigo-100 bg-opacity-50"
            : ""
        } hover:bg-zimx-indigo-100 hover:bg-opacity-50 transition-all duration-200`}
        key={address?.id}
        onClick={() => onSelectAddress(address.id!)}
      >
        <div className="flex items-start">
          <div className="flex flex-col text-sm text-gray-600">
            <label className="mb-0 pb-0">
              {address.firstName} {address.lastName}
            </label>
            <label className="mb-0 pb-0">
              +{(address.country as Country).dialCode} {address.phoneNumber}
            </label>
            <label className="mb-0 pb-0">
              {address.streetAddress}, {(address.town as Town).name}
            </label>

            <label className="mb-0 pb-0">{(address.city as City).name}</label>
            <label className="mb-0 pb-0">
              {(address.country as Country).name}
            </label>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="w-full flex flex-col relative bg-transparent md:bg-white rounded-md overflow-hidden sm:shadow-none md:shadow-md">
      <div
        className="flex w-full  flex-col z-10 sm:py-0 md:py-4   "
        style={{ minHeight: 300 }}
      >
        <div className="w-full flex justify-between items-center sm:px-0 md:px-4">
          <label className="text-2xl text-gray-700 font-bold border-b-0 mb-0">
            Addresses
          </label>

          <button
            className=" padding-0 flex items-center justify-center font-bold text-indigo-800 border-indigo-800 border-2 rounded-md px-4 py-1 truncate shadow-lg"
            type="button"
            onClick={onClickAddAddress}
          >
            {" "}
            <FontAwesomeIcon
              icon={faPlus}
              className="text-indigo-800 text-xl mr-2"
            />
            Add new address
          </button>
        </div>

        <div className="w-full border-b h-2 sm:px-0 md:px-4" />
        {renderAddresses()}
        <div className="mt-6 sm:px-0 md:px-4">{children}</div>
      </div>
    </div>
  );
};
