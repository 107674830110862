import { ActionInterface } from '../action.types';
import { ICartItem } from '../actions/cart.actions';
import * as actionTypes from '../action.types';

type ICartReducer = {
	items: ICartItem[];
};

const initialState = {
  items: [],
};

export const CartReducer = (
  state: ICartReducer = initialState,
  action: ActionInterface,
): ICartReducer => {
  switch (action.type) {
  case actionTypes.API_GET_CART:
    return { ...state, items: action.payload };
  default:
    return state;
  }
};
