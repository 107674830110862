import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { ActionInterface } from "../action.types";
import * as actionTypes from "../action.types";
import { fetchFactory, RequestMethods } from "./fetchFactory";

export const loginUser = (user: any): ActionInterface => ({
  type: actionTypes.API_LOGIN_USER,
  payload: user,
});

export const logoutUser = (): ActionInterface => ({
  type: actionTypes.API_LOGOUT_USER,
});

export const authenticateWithGoogle = () => async (dispatch: Dispatch) => {
  try {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    const signIn = await signInWithPopup(auth, provider);
    const { user } = signIn;
    if (user) {
      const token = await user.getIdToken();
      const response = await fetchFactory({
        endpoint: "/api/auth/register",
        token,
        errorMessage: "Failed to create account",
        successMessage: "Registered account successfully",
        method: RequestMethods.POST,
      });
      dispatch(loginUser({ ...response, token }));
    }
  } catch (error) {
    toast.error("Failed to login");
  }
};

export const authenticateWithFacebook = () => async (dispatch: Dispatch) => {
  try {
    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    const signIn = await signInWithPopup(auth, provider);
    const { user } = signIn;
    if (user) {
      const token = await user.getIdToken();
      const response = await fetchFactory({
        endpoint: "api/auth/register",
        token,
        errorMessage: "Failed to create account",
        successMessage: "Registered account successfully",
        method: RequestMethods.POST,
      });
      dispatch(loginUser({ ...response, token }));
    }
  } catch (error) {
    toast.error("Failed to login");
  }
};

export const registerWithEmail = (email: string, password: string) => async (
  dispatch: Dispatch,
) => {
  try {
    const auth = getAuth();
    const account = await createUserWithEmailAndPassword(auth, email, password);

    const { user } = account;
    if (user) {
      const token = await user.getIdToken();
      const response = await fetchFactory({
        endpoint: "api/auth/register",
        token,
        errorMessage: "Failed to create account",
        successMessage: "Logged into account successfully",
        method: RequestMethods.POST,
      });
      dispatch(loginUser({ ...response, token }));
    }
  } catch (error) {
    toast.error("Failed to create account");
  }
};

export const signinWithEmail = (email: string, password: string) => async (
  dispatch: Dispatch,
) => {
  try {
    const auth = getAuth();
    const account = await signInWithEmailAndPassword(auth, email, password);

    const { user } = account;
    if (user) {
      const token = await user.getIdToken();
      const response = await fetchFactory({
        endpoint: "/api/auth/register",
        token,
        errorMessage: "Failed to create account",
        successMessage: "Logged into account successfully",
        method: RequestMethods.POST,
      });
      dispatch(loginUser({ ...response, token }));
    }
  } catch (error) {
    toast.error("Failed to login");
  }
};
