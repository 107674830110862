import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { animated, useSpring } from "react-spring";
import { useHistory } from "react-router-dom";
import "./variation.css";
import { Button } from "../common/button/button";
import { ZInput } from "../common/input/ZInput";

const VariationPill: any = ({
  value,
  removeValue,
}: {
  value: string;
  removeValue: (value: string) => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visible, setVisible] = useState(true);

  const animatedTransition = useSpring({
    from: {
      opacity: 0,
      transform: "scale(0.5)",
    },
    to: {
      opacity: 1,
      transform: "scale(1)",
    },
  });

  const removeItem = (): void => {
    setVisible(false);
    removeValue(value);
  };

  return (
    <div
      data-testid="variation-pill"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <animated.div style={animatedTransition} className="variation-pill">
        <p className="variation-pill-label">{value}</p>
        <div className="variation-pill-remove" onClick={removeItem}>
          <FontAwesomeIcon icon={faTimes} color="white" />
        </div>
      </animated.div>
    </div>
  );
};

export const VariationProperties: React.FC<{
  variationKey: string;
  productVariations: {
    [key: string]: any;
  };
  onAddVariationValue: (variationKey: string, variationValue: string) => void;
  onRemoveVariationValue: (
    variationKey: string,
    variationValue: string,
  ) => void;
}> = ({
  variationKey,
  productVariations,
  onAddVariationValue,
  onRemoveVariationValue,
}) => {
  const [textValue, setTextValue] = useState<string>("");

  const onClick = () => {
    if (textValue.trim() !== "") {
      onAddVariationValue(variationKey, textValue?.trim());
      setTextValue("");
    }
  };

  const removeValue = () => {
    if (textValue.trim() !== "") {
      onRemoveVariationValue(variationKey, textValue?.trim());
      setTextValue("");
    }
  };

  const onChange = (e: any) => {
    const { value } = e.target;
    setTextValue(value);
  };

  return (
    <div className="variation-properties">
      <div className="variation-card">
        <h3 className="property-label">{variationKey}</h3>

        <div className="flex flex-row items-center">
          <div className="flex flex-1">
            <ZInput onChange={onChange} value={textValue} />
          </div>
          <div className="w-4" />
          <div className="w-24">
            <Button title={<FontAwesomeIcon icon={faPlus} color="white" size="lg" />} onClick={onClick} />
          </div>
        </div>

        <div className="variation-prop-grid">
          {productVariations.has(variationKey) &&
            productVariations
              .get(variationKey)
              ?.map?.((value: string) => (
                <VariationPill
                  key={value}
                  value={value}
                  removeValue={removeValue}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export const AddVariationProperties: React.FC<{
  variations: string[];
  onSubmitVariations: Function;
  currentVariations: Map<string, string[]>;
}> = ({ variations, onSubmitVariations, currentVariations }) => {
  const history = useHistory();
  const [productvariations, setProductVariations] = useState<
    Map<string, string[]>
  >(new Map());

  const onAddVariationValue = (
    variationKey: string,
    variationValue: string,
  ) => {
    const prevState = productvariations.get(variationKey) as string[];
    const updatedVariations = Array.from(
      new Set([...prevState, variationValue]),
    );
    productvariations.set(variationKey, updatedVariations);
  };

  const onRemoveVariationValue = (
    variationKey: string,
    variationValue: string,
  ) => {
    const prevState = productvariations.get(variationKey);

    if (Array.isArray(prevState)) {
      const updatedVariations = prevState.filter(
        (value: string) => value !== variationValue,
      );
      setProductVariations({
        ...productvariations,
        [variationKey]: updatedVariations,
      });
    }
  };

  useEffect(() => {
    const initialState: Map<string, string[]> = new Map();
    // eslint-disable-next-line array-callback-return
    variations?.map?.((vkey: string): void => {
      initialState.set(vkey, currentVariations.get(vkey) || []);
    });

    setProductVariations(initialState);
  }, [variations, currentVariations]);

  const gotoNextStep = () => {
    onSubmitVariations(productvariations);
    history.push("/shop/products/add/pricing");
  };

  const renderProductVariations = (): JSX.Element | JSX.Element[] =>
    variations?.map?.((variationKey: string) => (
      <VariationProperties
        key={variationKey}
        variationKey={variationKey}
        onAddVariationValue={onAddVariationValue}
        onRemoveVariationValue={onRemoveVariationValue}
        productVariations={productvariations}
      />
    ));

  return (
    <div
      className="variation-properties-container"
      data-test-id="variation-props-container"
    >
      <h2 className="text-gray-600 text-lg font-bold font-round ml-4 mb-4">
        Enter variation values
      </h2>
      {renderProductVariations()}

      <div className="fill" />

      <Button
        onClick={gotoNextStep}
        title="Next"
      />

    </div>
  );
};
