import { getAuth, User } from "firebase/auth";

import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { fetchFactory, RequestMethods } from "../../redux/actions/fetchFactory";
import { RootState } from "../../redux/store";

export const useAccount = (): {
  onUpdateUser: (user: Partial<User> & { name: string }) => void;
  onDeleteUser: () => void;
  user?: User | null;
} => {
  const reduxAccountState = useSelector((state: RootState) => state.user);
  const [user, setUser] = useState<User | null>();

  useEffect(() => {
    const authUser = getAuth().currentUser;
    setUser(authUser);
  }, []);

  const updateUser = useMutation(
    "user",
    async (body: Partial<User>) =>
      fetchFactory({
        endpoint: `/api/users`,
        method: RequestMethods.PUT,
        successMessage: "Updated display name successfully",
        errorMessage: "Failed to update display name",
        token: reduxAccountState.token,
        body,
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const deleteUser = useMutation(
    "user",
    async () =>
      fetchFactory({
        endpoint: `/api/users`,
        method: RequestMethods.DELETE,
        successMessage: "Deleted account successfully",
        errorMessage: "Failed to delete account",
        token: reduxAccountState.token,
      }),
    // eslint-disable-next-line function-paren-newline
  );
  const onUpdateUser = async (body: Partial<User>) => {
    await updateUser.mutate(body);
  };

  const onDeleteUser = async () => {
    await deleteUser.mutate();
  };
  return {
    onUpdateUser,
    onDeleteUser,
    user,
  };
};
