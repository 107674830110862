/* eslint-disable react/jsx-curly-newline */
import React from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProductGroup } from "../../../redux/reducers/shop.reducer";

interface ICartItem {
  quantity: number;
  productGroup: ProductGroup;
  _id: string;
  product: string;
}

interface QuantitityButtonProps {
  onIncreaseQuantity: ({ quantity, _id }: Partial<ICartItem>) => void;
  onDecreaseQuantity: ({ quantity, _id }: Partial<ICartItem>) => void;
  quantity: number;
  _id: string;
  label?: string;
}

export const QuantityButton: React.FC<QuantitityButtonProps> = ({
  onIncreaseQuantity,
  onDecreaseQuantity,
  _id,
  quantity,
  label,
}) => (
  <div className="flex  w-full  flex-col md:flex-row flex-wrap justify-between items-center md:mr-4">
    {label && (
      <p className="w-full h-full md:w-1/3 text-gray-700 font-bold text-lg mb-0 md:flex hidden items-center justify-center">
        {label}
      </p>
    )}
    <div className="flex w-full flex-1 hover:shadow-lg">
      <button
        type="button"
        className="bg-red-400 hover:text-xl  shadow-md h-10 py-2 rounded-l-lg w-1/3 text-white flex items-center justify-center hover:opacity-80"
        onClick={() =>
          onDecreaseQuantity({
            quantity: quantity - 1,
            _id,
          })
        }
      >
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <input
        type="text"
        className="w-1/3 text-center bg-gray-50 text-gray-700 font-rounded font-bold"
        value={quantity}
        min={1}
        disabled
      />
      <button
        type="button"
        className="bg-green-500 hover:text-xl py-2 h-10 rounded-r-md w-1/3 text-white flex items-center justify-center hover:opacity-80"
        onClick={() =>
          onIncreaseQuantity({
            quantity: quantity + 1,
            _id,
          })
        }
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  </div>
);
