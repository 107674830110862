/* eslint-disable indent */
import {
  faImage,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import parse from 'html-react-parser';

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  apiGetSingleProduct,
  updateProduct,
} from "../../../redux/actions/product.actions";
import {
  IShop,
  Product,
  ProductGroup,
} from "../../../redux/reducers/shop.reducer";

type RenderDescriptionType = {
  description: string;
  shopDetails?: IShop;
  productGroup: ProductGroup;
};

enum DescriptionTabs {
  DESCRIPTION = "DESCRIPTION",
  REVIEWS = "REVIEWS",
  ABOUT_SHOP = "ABOUT_SHOP",
  UPDATE_STOCK = "UPDATE_STOCK",
}

export const UpdateStock = ({
  productGroup,
}: {
  productGroup: ProductGroup;
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [productKeys, setProductKeys] = useState<string[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (productGroup?.products) {
      setProducts(productGroup.products);
      if (productGroup.products) {
        const {
          createdAt,
          updatedAt,
          _id,
          ...filteredProduct
        } = productGroup.products[0];
        const keys = Object.keys(filteredProduct);

        setProductKeys(keys);
      }
    }
  }, [productGroup]);

  const renderHeading = (): JSX.Element => (
    <div className="flex w-full items-center justify-center py-3 bg-blue-grey-700">
      {productKeys.map((key) => (
        <div className="flex capitalize flex-auto items-center justify-center text-white font-bold">
          {key}
        </div>
      ))}
      <div className="flex-1" />
    </div>
  );

  const onClickUpdate = (product: Product) => {
    dispatch(
      updateProduct(productGroup._id, productGroup.shop, {
        ...product,
        quantity: product.quantity.toString(),
        price: product.price.toString(),
      }),
    );
  };

  const onChangeItem = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    _id: string,
  ) => {
    const { value } = event.target;

    const updatedProducts = products.map((product) => {
      if (product._id === _id) {
        return { ...product, [key]: value };
      }

      return product;
    });

    setProducts(updatedProducts);
  };

  const renderProducts = (): JSX.Element[] =>
    products.map((product, index) => (
      <div
        className={`flex w-full text-gray-700 py-2 ${
          (index + 1) % 2 === 0 ? "bg-blue-grey-200" : "bg-transparent"
        }`}
      >
        {productKeys.map((pk: string) => {
          if (pk.toLowerCase() === "quantity" || pk.toLowerCase() === "price") {
            return (
              <input
                type="text"
                className="flex text-center py-1 flex-1 mx-2 rounded-md border-gray-400 border-2 focus:border-zimx-indigo-dark bg-gray-100 focus:outline-none"
                value={(product as any)[pk]}
                onChange={(event) => {
                  onChangeItem(event, pk.toLowerCase(), product._id as string);
                }}
              />
            );
          }
          return (
            <div className="flex flex-1 mx-2 items-center justify-center">
              {(product as any)[pk]}
            </div>
          );
        })}
        <div className="mx-2 flex flex-1">
          <button
            type="button"
            className="flex flex-1 bg-gray-300 shadow-md text-gray-700 font-bold font-rounded mx-4  px-3 py-1 text-center justify-center items-center hover:opacity-80 hover:bg-zimx-indigo-dark hover:text-white rounded-md"
            onClick={() => onClickUpdate(product)}
          >
            Update
          </button>
        </div>
      </div>
    ));

  return (
    <div>
      {renderHeading()}
      {renderProducts()}
    </div>
  );
};

const RenderDescription: React.FC<RenderDescriptionType> = ({
  description,
  shopDetails,
  productGroup,
}): JSX.Element => {
  const [tab, setTab] = useState<DescriptionTabs>(DescriptionTabs.DESCRIPTION);

  const onClickTab = (selectedTab: DescriptionTabs): void =>
    setTab(selectedTab);

  const renderDescriptionSection = (): JSX.Element => (
    <div className="w-full p-8">
      {parse(description!)}
    </div>
  );

  const renderShopDetails = (): JSX.Element => (
    <div className="w-full flex flex-col p-8 h-80 md:flex-row">
      <div className="w-full md:w-2/5 flex pl-8 flex-col flex-1 items-start flex-wrap">
        <div className="flex w-full">
          <div className="flex flex-col justify-center items-center">
            <img src={shopDetails?.logo} className="w-20 h-20 rounded-full" />
            <p className="font-bold text-xl font-rounded text-gray-700 mb-0 mt-2">
              {shopDetails?.name}
            </p>
          </div>
          <div className="flex flex-col font-bold text-blue-grey-800 font-rounded mx-8 opacity-80 text-lg justify-between">
            <p className="mb-0">{productGroup?.shopDetails?.address.streetAddress}</p>
            <p className="mb-0">{shopDetails?.address.town}</p>
            <p className="mb-0">{shopDetails?.address.city}</p>
            <p className="mb-0">{shopDetails?.address.country}</p>
          </div>
        </div>

        {shopDetails?.createdAt && (
          <div className="bg-gray-300 w-full mt-8 p-2 rounded-lg flex justify-between">
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <div>Registered on</div>
            <div>
              {new Date(shopDetails?.createdAt).toLocaleDateString("en-GB")}
            </div>
          </div>
        )}
      </div>
      <div className="w-full bg-gradient-to-r from-blue-grey-50 absolute top-0 bottom-0 right-0 md:w-3/5" />
      <div className="absolute w-3/5 bg-gradient-to-r from-blue-grey-50 to-transparent z-10 right-0 top-0 h-screen" />
    </div>
  );

  return (
    <div
      className="flex flex-col w-full bg-blue-grey-50 rounded-b-lg overflow-hidden"
      style={{ minHeight: "40vh" }}
    >
      <div className="w-full flex bg-gray-300 font-rounded">
        <button
          type="button"
          className={`flex-auto flex py-2 items-center justify-center font-bold ${
            tab === DescriptionTabs.DESCRIPTION
              ? "bg-blue-grey-700 border-b-2 border-red-500 text-white"
              : ""
          }`}
          onClick={() => onClickTab(DescriptionTabs.DESCRIPTION)}
        >
          Description
        </button>
        <button
          type="button"
          className={`flex-auto flex py-2 items-center justify-center font-bold ${
            tab === DescriptionTabs.REVIEWS
              ? "bg-blue-grey-700 border-b-2 border-red-500 text-white"
              : ""
          }`}
          onClick={() => onClickTab(DescriptionTabs.REVIEWS)}
        >
          Reviews
        </button>
        <button
          type="button"
          className={`flex-auto flex py-2 items-center justify-center font-bold ${
            tab === DescriptionTabs.ABOUT_SHOP
              ? "bg-blue-grey-700 border-b-2 border-red-500 text-white"
              : ""
          }`}
          onClick={() => onClickTab(DescriptionTabs.ABOUT_SHOP)}
        >
          About Shop
        </button>
        <button
          type="button"
          className={`flex-auto hidden md:flex py-2 items-center justify-center font-bold ${
            tab === DescriptionTabs.UPDATE_STOCK
              ? "bg-blue-grey-700 border-b-2 border-red-500 text-white"
              : ""
          }`}
          onClick={() => onClickTab(DescriptionTabs.UPDATE_STOCK)}
        >
          Update stock
        </button>
      </div>

      <div className="w-full flex flex-col relative">
        {tab === DescriptionTabs.DESCRIPTION && renderDescriptionSection()}
        {tab === DescriptionTabs.ABOUT_SHOP && renderShopDetails()}
        {tab === DescriptionTabs.UPDATE_STOCK && (
          <UpdateStock productGroup={productGroup} />
        )}
      </div>
    </div>
  );
};

export const ViewSingleProduct = (): JSX.Element => {
  const dispatch = useDispatch();
  const [state, setState] = useState<{
    activeKey: string;
    variationProperties: Map<string, string[]>;
  }>({
    activeKey: "",
    variationProperties: new Map(),
  });
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [product, setProduct] = useState<ProductGroup>({
    imageUrls: [],
    _id: "",
    productDescription: "",
    name: "",
    products: [],
    shop: "",
    variations: [],
  });
  const { productId, shopId } = useParams<{
    productId: string;
    shopId: string;
  }>();
  const [imgIndex, setImageIndex] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState({});
  const [filteredOptions, setFilteredOptions] = useState<Product[]>([]);

  const getProduct = async () => {
    const response = ((await dispatch(
      apiGetSingleProduct(shopId, productId),
    )) as unknown) as ProductGroup;
    if (response) {
      setProduct(response);
      setFilteredOptions(response.products);
    }
  };

  const onSelectOption = (key: string, value: string) => {
    setSelectedOption({ ...selectedOption, [key]: value });
  };

  useEffect(() => {
    if (productId && shopId) {
      getProduct();
    }
  }, [productId, shopId]);

  useEffect(() => {
    const tempVariationList: Map<string, string[]> = new Map();
    product?.variations?.map((v: string) => {
      product.products.map((p: any): any => {
        const currentVariations: string[] =
          (tempVariationList.get(v) as string[]) || [];
        const value: string = p[v];
        const list = Array.from(new Set([...currentVariations, value]));
        tempVariationList.set(v, list);
        return 0;
      });
      return 0;
    });

    setState({ ...state, variationProperties: tempVariationList });
  }, [product?.variations]);

  useEffect(() => {
    const optionKeys = Object.keys(selectedOption);
    if (optionKeys.length) {
      const updatedOptions = product.products.filter(
        (option) =>
          optionKeys.every(
            (key) => (option as any)[key] === (selectedOption as any)[key],
          ),
        // eslint-disable-next-line function-paren-newline
      );

      setFilteredOptions(updatedOptions);
    }
  }, [selectedOption]);

  const renderImages = (): JSX.Element[] | null => {
    if (product?.imageUrls) {
      return product?.imageUrls?.map?.(
        (image, index): JSX.Element => (
          <button
            type="button"
            onClick={() => setImageIndex(index)}
            className="w-[25%]"
          >
            <img
              className="h-full hover:shadow-xl rounded-lg shadow-lg overflow-scroll"
              src={image}
              alt=""
              style={{ aspectRatio: "1" }}
            />
          </button>
        ),
      );
    }
    return null;
  };

  const renderVariations = () =>
    product.variations.map((variation: string) => {
      const variationValues = state.variationProperties.get(variation);
      return (
        <>
          {variationValues?.length && (
            <div className="w-full">
              <p className="mt-2 mb-1 text-lg font-rounded text-gray-700 ml-4 font-bold">
                {variation}
              </p>
              <div className="grid grid-flow-row grid-cols-4 gap-y-1 gap-x-2">
                {variationValues?.map((value) => (
                  <button
                    type="button"
                    className={`w-full transition-all duration-300 rounded-md focus:outline-none text-center
                      hover:shadow-md cursor-pointer  
                      shadow-md py-2 px-1 overflow-ellipsis relative overflow-hidden 
                      hover:opacity-80 ${
                        (selectedOption as any)[variation] === value
                          ? "bg-zimx-indigo-100 text-white"
                          : "bg-gray-300 text-gray-600"
                      }`}
                    onClick={() => onSelectOption(variation, value)}
                  >
                    <div className="absolute w-3 h-3 opacity-30 bg-green-400 rotate-45 rounded-sm top-2 left-1" />
                    <div className="absolute w-5 h-5 opacity-30 bg-indigo-400 rounded-full top-5 left-2" />
                    <div className="absolute w-14 h-14 opacity-30 bg-blue-500 rounded-full -right-7 -bottom-7" />
                    {value}
                  </button>
                ))}
              </div>
            </div>
          )}
        </>
      );
    });

  const renderPrices = (): JSX.Element | null => {
    const prices = filteredOptions.map((option) => Number(option.price));
    if (prices.length) {
      const maxPrice = Math.max(...prices);
      const minPrice = Math.min(...prices);
      const priceRange = Array.from(new Set([minPrice, maxPrice]));
      return (
        <div className="flex w-full justify-between items-center font-bold text-4xl text-zimx-indigo-dark">
          {priceRange.length > 1 ? (
            <div className="flex justify-end items-center flex-auto text-right p-4">
              <div className="flex-auto text-right pr-4">
                <>$</>
                {minPrice}
              </div>
              <div className="flex-auto h-1 border-2 border-zimx-indigo-dark bg-zimx-indigo-dark" />
              <div className="flex-auto pl-4 text-left">
                <>$</>
                {maxPrice}
              </div>
            </div>
          ) : (
            <div className="flex justify-end flex-auto text-right p-4">
              <>$</>
              <div>{maxPrice}</div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const onAdditem = () => {
    setSelectedQuantity((prevState) => prevState + 1);
  };

  const onRemoveitem = () => {
    if (selectedQuantity > 1) {
      setSelectedQuantity((prevState) => prevState - 1);
    }
  };

  return (
    <div className="w-full flex flex-col max-w-screen-lg">

      <div className="w-full items-start flex flex-col md:flex-row">
        <div className="w-full flex-col flex-wrap flex lg:justify-start lg:w-1/2 md:px-8">
          {/* Product Images */}

          {product?.imageUrls.length ? (
            <img
              src={product.imageUrls[imgIndex]}
              alt="Featured"
              className="w-full h-full rounded-xl shadow-xl transition-all duration-300"
              style={{ aspectRatio: "1" }}
            />
            ) : (
              <div
                className=" w-full h-full rounded-xl shad bg-zimx-indigo-100 bg-opacity-50 flex items-center justify-center flex-col"
                style={{ aspectRatio: "1" }}
              >
                      <FontAwesomeIcon
                        className="text-4xl"
                        icon={faImage}
                        color="white"
                        size="9x"
                      />
              </div>
              )}

          <div
            className="flex w-full flex-row mt-4 gap-2"
          >
            {product.imageUrls ? renderImages() : (
        <button
          type="button"
          className="flex-[0.25]"
        >
                   <div
                     className=" w-full h-full rounded-xl shad bg-zimx-indigo-100 bg-opacity-50 flex items-center justify-center flex-col"
                     style={{ aspectRatio: "1" }}
                   >
                      <FontAwesomeIcon
                        className="text-xl"
                        icon={faImage}
                        color="white"
                        size="9x"
                      />
                   </div>
        </button>
        )}
          </div>
        </div>

        <div className="w-full md:w-1/2 relative h-full flex flex-col justify-between">

          <div className="w-full">
            <p className="text-2xl font-bold sm:mt-4 md:mt-0">
              {product.name}
            </p>
            <div className="md:mt-4" />
            {renderVariations()}
          </div>

          <div className="self-end md:mr-2 w-full md:w-2/3 ">
            <div className="flex w-full flex-col md:flex-row flex-wrap justify-end items-center">
              {renderPrices()}
              <p className="w-full md:w-1/2 text-gray-700 font-rounded font-bold mb-0 md:flex hidden items-center justify-center ">
                Quantitity
              </p>
              <div className="flex w-full md:w-1/2 md:px-2">
                <button
                  type="button"
                  className="bg-zimx-indigo-dark shadow-md h-10 py-2 rounded-l-lg w-1/3 text-white flex items-center justify-center hover:opacity-80"
                  onClick={onRemoveitem}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <input
                  type="text"
                  className="w-1/3 text-center bg-gray-50 text-gray-700 font-rounded font-bold"
                  value={selectedQuantity}
                  min={1}
                  disabled
                />
                <button
                  type="button"
                  className="bg-zimx-indigo-dark py-2 h-10 rounded-r-lg w-1/3 text-white flex items-center justify-center hover:opacity-80"
                  onClick={onAdditem}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full mt-4">
              <button
                type="button"
                className="mt-4 md:mt-0 w-full bg-zimx-indigo-100 hover:opacity-80 py-2 rounded-lg text-white font-bold md:mx-2"
              >
                Add to Cart
              </button>

              <button
                type="button"
                className="mt-4 md:mt-0 w-full bg-zimx-indigo-dark hover:opacity-80 py-2 rounded-lg text-white font-bold md:mx-2"
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div>

      {useMemo(
        () => (
          <div className="w-full mt-8">
            <RenderDescription
              description={product.productDescription}
              shopDetails={product.shopDetails}
              productGroup={product}
            />
          </div>
        ),
        [product],
      )}
    </div>
  );
};
