import React from "react";
import { ReactSVG } from "react-svg";
import VeelyLogo from "../../../assets/icons/veely.svg";

export const AdminNav = () => (
  <nav className="flex w-full flex-col  md:flex-row bg-white py-2 justify-between items-center shadow-md z-50 sticky top-0 min-h-[68px]">
    <div className="font-rounded hidden md:flex text-zimx-indigo-dark font-bold text-3xl px-4 w-1/6">
      <ReactSVG src={VeelyLogo} />
      admin
    </div>

    <div className="flex flex-row gap-4 mr-4">
      <a href="/admin/countries">Countries</a>
      <a href="/admin/cities">Cities</a>
      <a href="/admin/towns">Towns</a>
      <a href="/admin/brands">Brands</a>
    </div>
  </nav>
);
