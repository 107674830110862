// add variation reducer here
interface variationAction {
	type: string;
	payload?: {
		variationKey: string;
		variationValue: string;
	};
	initialState?: object;
}

const ADD_VARIATION = 'add_variation';
const ADD_VARIATION_KEY = 'ADD_VARIATION_KEY';
const REMOVE_VARIATION = 'remove_variation';
const HYDRATE = 'hydrate';
const ADD_PRICING_DEPENDENCY = 'ADD_PRICING_DEPENDENCY';
const ADD_QUANTITY_DEPENDENCY = 'ADD_QUANTITY_DEPENDENCY';
const ADD_PRICED_PRODUCTS = 'ADD_PRICED_PRODUCTS';
const ADD_PRODUCT_QUANTITIES = 'ADD_PRODUCT_QUANTITIES';

export const variationReducer = (
  state: any = {
    pricingDependency: [],
    weightDependency: [],
    quantityDependency: [],
    imageDependency: [],
    variationKeys: [],
    products: [],
  },
  action: variationAction,
) => {
  const { type } = action;
  // some actions may or may not be defined
  const payload = action?.payload;
  switch (type) {
  case ADD_VARIATION_KEY:
    if (state.variationKeys.includes(payload?.variationKey)) {
      const updatedVariations = state.variationKeys.filter(
        (variation: string) => variation !== payload?.variationKey,
      );

      return { ...state, variationKeys: updatedVariations };
    }

    return {
      ...state,
      variationKeys: [...state.variationKeys, payload?.variationKey],
    };

  case ADD_VARIATION:
    if (payload) {
      const updatedVariations: string[] = Array.from(
        new Set([
          ...state[payload?.variationKey],
          payload.variationValue.trim(),
        ]),
      );
      return { ...state, [payload.variationKey]: updatedVariations };
    }
    break;
  case REMOVE_VARIATION:
    if (payload) {
      const updatedVariations = state[payload.variationKey].filter(
        (v: string) => v !== payload.variationValue,
      );
      return { ...state, [payload.variationKey]: updatedVariations };
    }
    break;

  case ADD_PRICING_DEPENDENCY:
    let updatedPricingDependency;
    if (payload) {
      const temp = new Set([
        ...state.pricingDependency,
        payload.variationKey,
      ]);
      updatedPricingDependency = Array.from(temp);

      return { ...state, pricingDependency: updatedPricingDependency };
    }
    break;
  case ADD_QUANTITY_DEPENDENCY:
    let updatedQuantityDependency;
    if (payload) {
      const temp = new Set([
        ...state.quantityDependency,
        payload.variationKey,
      ]);
      updatedQuantityDependency = Array.from(temp);

      return { ...state, quantityDependency: updatedQuantityDependency };
    }
    break;
  case ADD_PRICED_PRODUCTS:
    return { ...state, products: action.payload };
  case ADD_PRODUCT_QUANTITIES:
    return { ...state, products: action.payload };
  case HYDRATE:
    if (action.initialState) {
      return { ...state, ...action.initialState };
    }
    break;
  default:
    return state;
  }
};
