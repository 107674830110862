/* eslint-disable indent */
import { faImage, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { apiGetProduct } from "../../redux/actions/product.actions";
import { Product, ProductGroup } from "../../redux/reducers/shop.reducer";

import { addToCart } from "../../redux/actions/cart.actions";
import { RenderDescription } from "./ProductDetails";
import { ScreenContainer } from "../common/screenContainer";
import { WhatsappButton } from "../common/whatsappButton/WhatsappButton";
import { Country } from "../admin/countries/Countries.types";

export const ProductScreen = (): JSX.Element => {
  const dispatch = useDispatch();
  const [state, setState] = useState<{
    activeKey: string;
    variationProperties: Map<string, string[]>;
  }>({
    activeKey: "",
    variationProperties: new Map(),
  });
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState<ProductGroup>({
    imageUrls: [],
    _id: "",
    productDescription: "",
    name: "",
    products: [],
    shop: "",
    variations: [],
  });
  const { productId } = useParams<{
    productId: string;
    shopId: string;
  }>();
  const [imgIndex, setImageIndex] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState({});
  const [filteredOptions, setFilteredOptions] = useState<Product[]>([]);

  const getProduct = async () => {
    const response = ((await dispatch(
      apiGetProduct(productId),
    )) as unknown) as ProductGroup;
    if (response) {
      const loadImages = response.imageUrls.map(
        // eslint-disable-next-line no-return-await
        async (item) => {
          const image = new Image();

          if (item) {
            const imageLoaded = new Promise((resolve, reject) => {
              image.src = item;
              image.onload = () => resolve(image);
              image.onerror = () => reject();
            });

            return imageLoaded;
          }
          return image;
        },
      );
      await Promise.all(loadImages);
      setProduct(response);
      setTimeout(() => setIsLoading(false), 1000);
      setFilteredOptions(response.products);
    }
  };

  const onSelectOption = (key: string, value: string) => {
    setSelectedOption({ ...selectedOption, [key]: value });
  };

  useEffect(() => {
    if (productId) {
      getProduct();
    }
  }, [productId]);

  useEffect(() => {
    const tempVariationList: Map<string, string[]> = new Map();
    product?.variations?.map((v: string) => {
      product.products.map((p: any): any => {
        const currentVariations: string[] =
          (tempVariationList.get(v) as string[]) || [];
        const value: string = p[v];
        const list = Array.from(new Set([...currentVariations, value]));
        tempVariationList.set(v, list);
        return 0;
      });
      return 0;
    });

    setState({ ...state, variationProperties: tempVariationList });
  }, [product?.variations]);

  useEffect(() => {
    const optionKeys = Object.keys(selectedOption);
    if (optionKeys.length) {
      const updatedOptions = product.products.filter(
        (option) =>
          optionKeys.every(
            (key) => (option as any)[key] === (selectedOption as any)[key],
          ),
        // eslint-disable-next-line function-paren-newline
      );

      setFilteredOptions(updatedOptions);
    }
  }, [selectedOption]);

  const renderImages = (): JSX.Element[] | null => {
    if (product?.imageUrls) {
      return product?.imageUrls.map(
        (image, index): JSX.Element => (
          <button
            type="button"
            onClick={() => setImageIndex(index)}
            className="w-full"
          >
            <img
              className="w-full hover:shadow-xl rounded-lg shadow-lg overflow-scroll"
              src={image}
              alt=""
              style={{ aspectRatio: "1" }}
            />
          </button>
        ),
      );
    }
    return null;
  };

  const renderVariations = () =>
    product.variations.map((variation: string) => {
      const variationValues = state.variationProperties.get(variation);
      return (
        <>
          {variationValues?.length && (
            <div className="w-full">
              <p className="mt-2 mb-1 text-lg font-rounded text-gray-700 ml-4 font-bold">
                {variation}
              </p>
              <div className="grid grid-flow-row grid-cols-3 md:grid-cols-4 gap-y-1 gap-x-2">
                {variationValues?.map((value) => (
                  <button
                    type="button"
                    className={`w-full transition-all duration-300 rounded-md focus:outline-none text-center
                      hover:shadow-md cursor-pointer  
                      shadow-md py-2 px-1 overflow-ellipsis relative overflow-hidden 
                      hover:opacity-80 ${
                        (selectedOption as any)[variation] === value
                          ? "bg-zimx-indigo-100 text-white"
                          : "bg-gray-300 text-gray-600"
                      }`}
                    onClick={() => onSelectOption(variation, value)}
                  >
                    <div className="absolute w-3 h-3 opacity-30 bg-green-400 rotate-45 rounded-sm top-2 left-1" />
                    <div className="absolute w-5 h-5 opacity-30 bg-indigo-400 rounded-full top-5 left-2" />
                    <div className="absolute w-14 h-14 opacity-30 bg-blue-500 rounded-full -right-7 -bottom-7" />
                    {value}
                  </button>
                ))}
              </div>
            </div>
          )}
        </>
      );
    });

  const renderPrices = (): JSX.Element | null => {
    const prices = filteredOptions.map((option) => Number(option.price));
    if (prices.length) {
      const maxPrice = Math.max(...prices);
      const minPrice = Math.min(...prices);

      return (
        <div className="flex justify-between items-center text-3xl font-bold text-zimx-indigo-dark">
          {prices.length > 1 ? (
            <>
              <div className="flex-auto text-right mr-1">
                <>$</>
                {minPrice}
              </div>
              <div className="flex-auto h-1 border-2 border-zimx-indigo-dark bg-zimx-indigo-dark" />
              <div className="flex-auto text-left ml-1">
                <>$</>
                {maxPrice}
              </div>
            </>
          ) : (
            <div className="flex justify-end flex-auto text-right">
              <>$</>
              <div>{maxPrice}</div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const onAdditem = () => {
    if (selectedQuantity < filteredOptions[0].quantity) {
      setSelectedQuantity((prevState) => prevState + 1);
    }
  };

  const onRemoveitem = () => {
    if (selectedQuantity > 1) {
      setSelectedQuantity((prevState) => prevState - 1);
    }
  };

  const onClickAddToCart = () => {
    if (filteredOptions.length === 1 && filteredOptions[0]._id) {
      dispatch(
        addToCart({
          quantity: selectedQuantity,
          product: filteredOptions[0]._id,
        }),
      );
    }
  };

  const shopCountry: Country = product.shopDetails?.address?.country as Country;
  const shopPhoneNumber: string = product.shopDetails?.address?.phoneNumber!;

  return (
<>
    <ScreenContainer
      loading={isLoading}
      footer={(
        <RenderDescription
          description={product.productDescription}
          shopDetails={product.shopDetails}
          productGroup={product}
        />
      )}
    >
      <div className="w-full items-stretch flex flex-col md:flex-row md:justify-between ">
        <div className="w-full flex-col flex-wrap flex lg:justify-start lg:w-2/5 px-0 md:px-8">
          {/* Product Images */}
          <div
            className="grid w-full lg:flex-row grid-rows-6 grid-cols-5 grid-flow-col gap-2"
            // style={{ aspectRatio: '1' }}
          >
            <div className="col-start-1 col-end-6 row-start-1 row-end-6 flex items-center">
              {product?.imageUrls.length ? (
                <img
                  src={product.imageUrls[imgIndex]}
                  alt="Featured"
                  className="w-full rounded-xl shadow-xl transition-all duration-300"
                  style={{ aspectRatio: "1" }}
                />
              ) : (
                <div
                  className=" w-full rounded-xl shad bg-zimx-indigo-100 bg-opacity-50 flex items-center justify-center flex-col"
                  style={{ aspectRatio: "1" }}
                >
                  <FontAwesomeIcon
                    className="text-4xl"
                    icon={faImage}
                    color="white"
                    size="9x"
                  />
                </div>
              )}
            </div>

            {renderImages()}
          </div>
        </div>

        <div className="w-full md:w-1/2 relative self-stretch flex flex-col bg-transparent md:bg-white sm:p-0 md:p-4 rounded-xl flex-1 shadow-none md:shadow-md mt-4 md:mt-0">
          <div className="w-full flex items-center font-bold text-gray-700  rounded-md">
            <p className="w-full flex items-center justify-between m-0 text-2xl ">
              <p className="m-0">{product.name}</p>
              {renderPrices()}
            </p>
          </div>
          <hr className=" bg-gray-100 mt-4" />
          <div className="w-full flex-1">{renderVariations()}</div>

          <hr className=" bg-gray-100 mt-4" />
          <div className="self-end justify-end w-full flex flex-col md:flex-row mt-4">
            <div className="flex w-full md:w-1/2 flex-col md:flex-row flex-wrap justify-between items-center">
              <WhatsappButton phoneNumber={`+${shopCountry?.dialCode}${shopPhoneNumber}`} />
              {false && (
            <>
              <p className="w-full h-full md:w-1/2 text-gray-700 font-bold text-lg mb-0 md:flex hidden items-center justify-center">
                              Quantitity
              </p>
              <div className="flex w-full md:w-1/2 hover:shadow-lg">
                <button
                  type="button"
                  className="bg-red-400 hover:text-xl  shadow-md h-10 py-2 rounded-l-lg w-1/3 text-white flex items-center justify-center hover:opacity-80"
                  onClick={onRemoveitem}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <input
                  type="text"
                  className="w-1/3 text-center bg-gray-50 text-gray-700 font-rounded font-bold"
                  value={selectedQuantity}
                  min={1}
                  disabled
                />
                <button
                  type="button"
                  className="bg-green-500 hover:text-xl py-2 h-10 rounded-r-md w-1/3 text-white flex items-center justify-center hover:opacity-80"
                  onClick={onAdditem}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>

              </div>
              <button
                type="button"
                className="w-full md:w-1/2 self-end transition-all md:shadow-md hover:shadow-xl hover:scale-105 bg-zimx-indigo-100 hover:opacity-90 py-2 rounded-lg text-white font-bold mt-4 md:mt-0"
                onClick={() => onClickAddToCart()}
              >
              Add to Cart
              </button>
            </>
          )}
            </div>

          </div>
        </div>
      </div>

    </ScreenContainer>

</>
  );
};
