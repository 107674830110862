import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import AuthScreen from "./components/auth/signInScreen";
import { HomeScreen } from "./components/home/HomeScreen";
import { store, persistedStore } from "./redux/store";
import { CreateShop } from "./components/user/createShop";
import "react-toastify/dist/ReactToastify.min.css";
import { Shop } from "./components/shop/Shop";
import { SearchScreen } from "./components/search/SearchScreen";
import { ProductScreen } from "./components/search/ProductScreen";
import { ZNav } from "./components/common/nav/ZNav";

import { PurchaseHistory } from "./components/purchaseHistory/PurchaseHistory";
import { Cart } from "./components/cart/Cart";
import { AdminRouter } from "./components/admin";
import { AddressScreen } from "./screens/Address";
import { ProtectedRoute } from "./components/common/protectedRoute/ProtectedRoute";
import { AccountDetails } from "./pages/AccountDetails";

const HomeRouter = (): JSX.Element => (
  <>

    <ProtectedRoute path="/search/:productId">
      <ZNav />
      <ProductScreen />
    </ProtectedRoute>

    <ProtectedRoute path="/cart">
      <ZNav />
      <Cart />
    </ProtectedRoute>

    <ProtectedRoute path="/purchase-history">
      <ZNav />
      <PurchaseHistory />
    </ProtectedRoute>

    <ProtectedRoute path="/add-address">
      <ZNav />
      <AddressScreen />
    </ProtectedRoute>

    <ProtectedRoute path="/admin">
      <AdminRouter />
    </ProtectedRoute>

    <ProtectedRoute path="/shop">
      <Shop />
    </ProtectedRoute>
    <ProtectedRoute path="/create-shop">
      <CreateShop />
    </ProtectedRoute>

  </>
);

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistedStore} loading={null}>
      <Router>
        <Switch>
          <Route exact path="/">
            <ZNav />
            <HomeScreen />
          </Route>

          <Route exact path="/account-details">
            <AccountDetails />
          </Route>

          <Route exact path="/search">
            <ZNav />
            <SearchScreen />
          </Route>
          <Route exact path="/authenticate">
            <AuthScreen />
          </Route>
          <HomeRouter />
        </Switch>
      </Router>
    </PersistGate>
    <ToastContainer hideProgressBar />
  </Provider>
);

export default App;
