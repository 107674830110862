const generateVariations = (
  variationKeys: string[],
  variationValues: Map<string, string[]>,
) => {
  const generateValues = (index: string) => {
    const productValues: [{ [key: string]: string }] = ([] as unknown) as [
      { [key: string]: string },
    ];
    if (variationValues && variationValues.has(index)) {
      variationValues
        ?.get(index)
        ?.map((value: string) => productValues.push({ [index]: value }));
    }
    return productValues;
  };

  // given an existing combination of values
  // this function generates a new combination given a new dimension
  const combineValues = (combinations: [], newValue: any) => {
    let temp: any[] = [];

    combinations.map((currentValues: any) => {
      if (!Object.keys(currentValues).includes(Object.keys(newValue)[0])) {
        const variation = { ...currentValues, ...newValue };
        temp.push(variation);
      } else {
        temp = currentValues;
      }
      return 0;
    });

    return temp;
  };

  let i = 0;
  let combinations = [];

  while (variationKeys[i]) {
    let first: any;
    let temp: any[] = [];
    if (variationKeys.length === 0) {
      break;
    }
    if (combinations.length === 0) {
      first = generateValues(variationKeys[0]);
    } else {
      first = combinations;
    }

    if (variationKeys.length === 1) {
      combinations = first;
      break;
    }

    if (variationKeys[i + 1]) {
      const second = generateValues(variationKeys[i + 1]);
      // for each value in the second array combine it and the first
      // array
      second.map((newValue) => {
        temp = temp.concat(combineValues(first, newValue));
        return 0;
      });
      combinations = temp;
    }

    i += 1;
  }

  return combinations;
};

export default generateVariations;
