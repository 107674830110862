import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import {
  fetchFactory,
  RequestMethods,
} from "../../../redux/actions/fetchFactory";
import { RootState } from "../../../redux/store";
import { City } from "./Cities.types";

export const useCities = (
  countryId?: string,
): {
  cities: City[];
  loading: boolean;
  error: boolean;
  onDeleteCity: (countryId: string) => void;
  onUpdateCity: (country: City) => void;
  // eslint-disable-next-line function-paren-newline
  onAddCity: (country: City) => void;
} => {
  const reduxAccountState = useSelector((state: RootState) => state.user);

  const { data = [], isLoading, isError, isLoadingError } = useQuery<City[]>(
    `cities ${countryId}`,
    async () =>
      fetchFactory({
        endpoint: `/api/cities${countryId ? `/country/${countryId}` : ""}`,
        method: RequestMethods.GET,
      }),
  );

  const deleteCity = useMutation(
    "cities",
    async (cityId: string) =>
      fetchFactory({
        endpoint: `/api/cities/${cityId}`,
        method: RequestMethods.DELETE,
        successMessage: "Deleted country successfully",
        errorMessage: "Failed to delete country",
        token: reduxAccountState.token,
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const addCity = useMutation(
    async (city: City) =>
      fetchFactory({
        endpoint: `/api/cities`,
        method: RequestMethods.POST,
        body: city,
        successMessage: "Added city succesfully",
        token: reduxAccountState.token,
        errorMessage: "Failed to add city",
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const updateCity = useMutation(
    "cities",
    async (city: City) =>
      fetchFactory({
        endpoint: `/api/cities/${city.id}`,
        method: RequestMethods.PUT,
        successMessage: "Deleted city successfully",
        errorMessage: "Failed to delete city",
        token: reduxAccountState.token,
        body: city,
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const onDeleteCity = (cityId: string) => deleteCity.mutate(cityId);

  const onUpdateCity = (city: City) => {
    updateCity.mutate(city);
  };

  const onAddCity = (city: City) => {
    addCity.mutate(city);
  };

  return {
    cities: data,
    loading: isLoading,
    error: isError || isLoadingError,
    onAddCity,
    onDeleteCity,
    onUpdateCity,
  };
};
