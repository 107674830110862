import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { fetchFactory, RequestMethods } from "../../redux/actions/fetchFactory";
import { ProductGroup } from "../../redux/reducers/shop.reducer";
import { RootState } from "../../redux/store";

interface ICartItem {
  quantity: number;
  productGroup: ProductGroup;
  _id: string;
  product: string;
}
export const useCart = () => {
  const reduxAccountState = useSelector((state: RootState) => state.user);
  const { data, isLoading, isLoadingError } = useQuery<ICartItem[]>(
    "cartItems",
    async () =>
      // eslint-disable-next-line no-return-await
      await fetchFactory({
        endpoint: "/api/cart",
        method: RequestMethods.GET,
        token: reduxAccountState.token,
      }),
  );

  const queryClient = useQueryClient();
  const deleteFromCart = useMutation(
    "cartItems",
    async (cartItemId: string) =>
      // eslint-disable-next-line no-return-await
      fetchFactory({
        endpoint: `/api/cart/${cartItemId}`,
        method: RequestMethods.DELETE,
        token: reduxAccountState.token,
        successMessage: "Deleted Item from Cart",
        errorMessage: "Failed to delete item from cart",
      }),
    {
      onSuccess: (response) => {
        queryClient.setQueryData("cartItems", response);
      },
    },
    // eslint-disable-next-line function-paren-newline
  );

  const updateCartQuantity = useMutation(
    "cartItems",
    async ({ _id, quantity }: Partial<ICartItem>) =>
      fetchFactory({
        endpoint: `/api/cart/${_id}`,
        method: RequestMethods.PUT,
        token: reduxAccountState.token,
        body: { quantity },
        successMessage: "Added item to cart",
        errorMessage: "Failed to update cart",
      }),
    {
      onSuccess: (response) => {
        queryClient.setQueryData("cartItems", response);
      },
    },
  );

  const onUpdateQuantity = ({ _id, quantity }: Partial<ICartItem>) => {
    updateCartQuantity.mutate({ _id, quantity });
  };

  const onDeleteCartItem = (id: string) => {
    deleteFromCart.mutate(id);
  };
  return {
    cart: data,
    loading: isLoading,
    error: isLoadingError,
    onUpdateQuantity,
    onDeleteCartItem,
  };
};
