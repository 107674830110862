import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import { toast } from "react-toastify";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faStore } from "@fortawesome/free-solid-svg-icons";
import { RootState } from "../../redux/store";
import { apiGetShop, createShop } from "../../redux/actions/shop.actions";

import ShopIllustraction from "../../assets/images/shop/shop.webp";
import { ZInput } from "../common/input/ZInput";
import { Button } from "../common/button/button";
import { AddressModal } from "../AddressModal/AddressModal.component";
import { Address } from "../../screens/Address";
import { useCountries } from "../admin/countries/useCountries";
import { useCities } from "../admin/cities/useCities";
import { useTowns } from "../admin/towns/useTowns";
import { Modal } from "../common/modal/Modal.component";

export function getCroppedImg(image: HTMLImageElement, crop: ReactCrop.Crop) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = crop.width as number;
  canvas.height = crop.height as number;
  const ctx = canvas.getContext("2d");

  if (crop) {
    ctx?.drawImage(
      image,
      (crop?.x as number) * scaleX,
      (crop?.y as number) * scaleY,
      (crop?.width as number) * scaleX,
      (crop?.height as number) * scaleY,
      0,
      0,
      crop?.width as number,
      crop?.height as number,
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          // blob.name = fileName as string;
          resolve(blob);
        },
        "image/jpeg",
        1,
      );
    });
  }
}

export const ImageCroppingModal = (props: ImageModalProps): JSX.Element => {
  const [crop, setCrop] = useState<ReactCrop.Crop>({
    aspect: 1,
    unit: "%",
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });

  const imgRef = useRef();
  const onLoad = useCallback((image: any) => {
    imgRef.current = image;
  }, []);

  const onCrop = async () => {
    const croppedImage = await getCroppedImg(
      (imgRef.current as any) as HTMLImageElement,
      crop,
    );

    props.onCrop(croppedImage);
    setCrop({ aspect: 1, unit: "%", width: 100, height: 100 });
  };

  return (
    <Modal
      className="overflow-hidden p-0"
      isOpen={props.isOpen}
      onToggle={props.toggleModal}
      title="Crop Image"
      titleClassName="px-4 pt-4"
      dividerClassName="mb-0"
      dismissOnTouchOutSide={false}
    >
      <ReactCrop
        src={props.image}
        className="w-full h-auto bg-contain"
        crop={crop}
        onChange={(newCrop) => {
          setCrop(newCrop);
        }}
        onImageLoaded={onLoad}
        style={{ width: "100%" }}
        imageStyle={{ width: "100%" }}
      />
      <div className="flex flex-1 items-end w-full">
        <div className="p-4 w-full">

          <Button title="Crop image" outline onClick={onCrop} />
          <div className="mt-4">
            <Button title="Save image" onClick={props.toggleModal} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const CreateShop = (): JSX.Element => {
  const [state, setState] = useState<{
    name: string;
    logo: string;
    address: Address;
  }>({
    name: "",
    logo: "",
    address: {
      town: "",
      city: "",
      country: "",
      streetAddress: "",
      phoneNumber: "",
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState<string>("");
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const reduxUserState = useSelector(
    (reduxState: RootState) => reduxState.user,
  );
  const dispatch = useDispatch();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const { countries } = useCountries();
  const { cities } = useCities();
  const { towns } = useTowns();
  const onChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, address: { ...state.address, [name]: value } });
  };

  const reduxShopState = useSelector(
    (reduxState: RootState) => reduxState.shop,
  );

  const onSelect = (fieldName: string, fieldValue: string) => {
    let temp = {};
    if (fieldName === "country") {
      temp = {
        ...state.address,
        [fieldName]: fieldValue,
        city: undefined,
        town: undefined,
      };
    } else if (fieldName === "city") {
      temp = {
        ...state.address,
        [fieldName]: fieldValue,
        town: undefined,
      };
    } else {
      temp = {
        ...state.address,

        [fieldName]: fieldValue,
      };
    }

    setState({ ...state, address: { ...state.address, ...temp } });
  };

  useEffect(() => {
    dispatch(apiGetShop());
  }, []);

  useEffect(() => {
    if (reduxShopState) {
      const { address, ...details } = reduxShopState;
      setState({ ...state, ...address, ...details });
    }
  }, [reduxShopState]);

  const uploadImage = async () => {
    if (imageBlob) {
      const uploadUrl = `shops/${reduxUserState?.tenant}/shop-logo.jpg`;

      const imageRef = ref(getStorage(), uploadUrl);
      const snapShot = await uploadBytes(imageRef, imageBlob);
      return getDownloadURL(snapShot.ref);
    }
    return null;
  };

  const createNewShop = async () => {
    try {
      const { address, ...rest } = state;
      const logo = await uploadImage();
      if (logo) {
        dispatch(createShop({ ...rest, address: address as Address, logo }));
      }
    } catch (error) {
      toast.error("Failed to upload logo");
    }
  };

  const toggleModal = () => {
    setIsOpen((prevState) => !prevState);
  };

  const onSelectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
    }
  };

  useEffect(() => {
    if (image.trim() !== "") setIsOpen(true);
  }, [image]);

  const onCropImage = (croppedImageFile: Blob) => {
    if (croppedImageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(croppedImageFile);
      reader.onloadend = () => {
        setImage(reader.result as string);
        setImageBlob(croppedImageFile);
      };
    }
  };

  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false);
  const toggleAddressModal = () => {
    setAddressModalOpen((prevState) => !prevState);
  };

  return (
    <div className="w-screen h-screen flex flex-col relative bg-white items-center">
      <nav className="w-full h-24 flex items-center sm:relative md:absolute left-0 top-0 font-bold px-3 py-10">
        <h2 className="text-zimx-indigo-dark font-rounded text-4xl font-bold">
          veely
        </h2>
      </nav>

      <div className="w-full h-full flex max-w-screen-xl md:items-center md:mt-0 sm:mt-8 sm:items-start justify-between">
        <div className="hidden md:flex w-1/2">
          <img className="w-full aspect-auto" src={ShopIllustraction} alt="" />
        </div>
        <div className=" w-full md:w-1/2 z-10 md:ml-20 md:shadow-lg p-3 flex md:flex-grow-0 sm:flex-grow flex-col rounded-lg">
          <div className="justify-between items-center flex mb-8">
            <label
              className="cursor-pointer relative text-gray-700"
              htmlFor="logo"
            >
              <div className="absolute bottom-0 right-0 bg-gray-400 w-8 h-8 shadow-md items-center justify-center flex rounded-full">

                <FontAwesomeIcon icon={faPen} />
              </div>
              {image.trim() !== "" ? (
                <img
                  src={image}
                  className="h-32 w-32 rounded-full bg-gray-700 bg-cover"
                />
              ) : (
                <div className="h-32 w-32 rounded-full bg-gray-300 items-center justify-center flex bg-cover">
                  {' '}
                  <FontAwesomeIcon icon={faStore} size="2x" />
                </div>
              )}

            </label>
            <input
              type="file"
              className="hidden"
              name="logo"
              id="logo"
              onChange={onSelectImage}
            />
          </div>

          <ZInput
            name="name"
            label="Shop Name"
            value={state.name}
            placeholder="Enter shop name here"
            onChange={onChange}
          />

          <h5 className="my-2 text-lg font-bold text-zimx-indigo-dark mb-2">Address</h5>

          {
            state.address.city &&
            state.address.country &&
            state.address.town &&
            state.address.phoneNumber &&
            state.address.streetAddress ? (
                <>
                  <div className="flex items-start">
                    <div className="flex flex-col text-md text-gray-600">

                      <label className="mb-0 pb-0">
                        +
                        {(countries.find((country) => country.id === state.address.country))
                          ?.dialCode}
                        {state.address.phoneNumber}
                      </label>
                      <label className="mb-0 pb-0">
                        {state.address.streetAddress}
,
                        {(towns.find((town) => town.id === state.address.town))?.name}
                      </label>

                      <label className="mb-0 pb-0">
                        {' '}
                        {(cities.find((city) => city.id === state.address.city))?.name}
                      </label>
                      <label className="mb-0 pb-0">
                        {(countries.find((country) => country.id === state.address.country))?.name}
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <Button outline title="Add shop address" onClick={toggleAddressModal} />
              )
          }
          <div className="mt-4 flex flex-1 items-end">

            <Button
              onClick={createNewShop}
              title="Create Shop"
            />
          </div>

          <AddressModal
            onChange={onChangeAddress}
            isOpen={addressModalOpen}
            address={state.address}
            onToggle={toggleAddressModal}
            onSelect={onSelect}
            isShop
            title="Add shop address"
            onClickSave={() => { toggleAddressModal(); }}
          />
        </div>
      </div>

      <ImageCroppingModal
        image={image}
        toggleModal={toggleModal}
        isOpen={isOpen}
        onCrop={onCropImage}
      />

      <div className="fixed top-0 right-0 hidden md:block">
        <div className="yellow-mural" />
        <div className="green-mural" />
        <div className="red-mural" />
        <div className="pink-mural" />
      </div>
    </div>
  );
};

type ImageModalProps = {
  image: string;
  toggleModal: ()=> void | Function;
  getCroppedImage?: Function;
  isOpen: boolean;
  onCrop: Function;
};
