import React from "react";

interface MenuListItemProps {
  label: string;
  leftIcon: React.ReactNode;
  onClick: Function;
  className?: string;
}

export const MenuListItem: React.FC<MenuListItemProps> = ({
  leftIcon,
  label,
  onClick,
  className,
}) => (
  <button
    type="button"
    onClick={() => onClick()}
    className={`flex w-full h-10 items-center px-4 hover:bg-gray-100 cursor-pointer ${className}`}
  >
    <div>{leftIcon}</div>
    <label className="m-0 ml-4 font-bold font-rounded">{label}</label>
  </button>
);
