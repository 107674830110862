import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { animated, Transition } from "react-spring";
import { toast } from "react-toastify";
import facebookIcon from "../../assets/icons/facebook-icon.svg";
import googleIcon from "../../assets/icons/google-icon.svg";
import illustration from "../../assets/images/auth/phone-illustration.webp";
import {
  authenticateWithFacebook,
  authenticateWithGoogle,
  registerWithEmail,
  signinWithEmail,
} from "../../redux/actions/user.actions";
import { ZInput } from "../common/input/ZInput";
import { useFirebaseAuth } from "../common/protectedRoute/ProtectedRoute";
import "../css/signInScreen.css";

const SignInScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState<{
    hasAccount: boolean;
    email: string;
    password: string;
    confirmPassword: string;
  }>({
    hasAccount: true,
    email: "",
    password: "",
    confirmPassword: "",
  });

  const { loggedIn, loading } = useFirebaseAuth();

  useEffect(() => {
    if (loggedIn && !loading) {
      history.push("/");
    }
  }, [loggedIn]);

  const googleAuth = () => {
    dispatch(authenticateWithGoogle());
  };

  const facebookAuth = () => {
    dispatch(authenticateWithFacebook());
  };

  const toggleHasAccount = () =>
    setState({ ...state, hasAccount: !state.hasAccount });

  const createWithEmail = async () => {
    const { email, password, confirmPassword } = state;
    try {
      // todo - check if email is valid
      if (password.length < 6) {
        throw new Error("Password should have more than 6 characters");
      }
      if (password !== confirmPassword) {
        throw new Error("Passwords do not match");
      }

      dispatch(registerWithEmail(email, password));
    } catch (error) {
      toast.error("Failed to create account");
    }
  };

  const loginWithEmail = async () => {
    const { email, password } = state;
    try {
      dispatch(signinWithEmail(email, password));
    } catch (error) {
      toast.error("Failed to login");
    }
  };

  // accepts an object {key: value}
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  return (
    <div className="min-h-screen min-w-screen overflow-hidden flex flex-col items-center">
      <div className="w-full md:ml-16">
        <p className="logo w-full">ivee</p>
      </div>
      <div className="flex flex-1 md:px-4 items-center -mt-20 w-full justify-between max-w-screen-xl relative">
        <div className="md:w-1/2 hidden md:block w-full mx-4">
          <img
            src={illustration}
            className="w-full"
            style={{ transform: "scale(1.5)" }}
            draggable={false}
          />

          <div
            className="w-24 h-24 -ml-24 rounded-full border-2 absolute mt-12 border-green-500"
            style={{ filter: "blur(2px)" }}
          />
          <div
            className="w-12 h-12 rounded-full absolute border-2 border-indigo-700"
            style={{ filter: "blur(2px)" }}
          />

          <div
            className="w-12 h-12 rounded-full top-20 absolute border-2 border-green-500"
            style={{ filter: "blur(2px)" }}
          />
        </div>
        <div className="w-full md:w-2/5 xl:w-1/3 p-4 mx-0 md:m-4 rounded-xl md:shadow-custom relative bg-white z-20">
          <h1
            className="leading-none text-3xl font-bold  pb-0 mb-0"
            style={{ color: "#765992" }}
          >
            Get Started
          </h1>

          <div className="text-xs text-blue-grey-800 mt-4">
            {!state.hasAccount
              ? "Already have an account?"
              : "Don't have an account?"}
            <button
              type="button"
              className="font-bold ml-1"
              style={{ color: "#765992" }}
              onClick={toggleHasAccount}
            >
              {!state.hasAccount ? "Log In" : "Register"}
            </button>
          </div>

          <div className="flex mt-8">
            <button
              type="button"
              className="border w-full h-12 flex items-center md-w-1/2 px-2 mr-1 rounded-md"
              onClick={googleAuth}
            >
              <img src={googleIcon} className="w-5 h-5" />
              <div className="font-bold ml-1 text-xs truncate overflow-ellipsis">
                {state.hasAccount
                  ? "Sign in with Google"
                  : "Sign up with Google"}
              </div>
            </button>

            <button
              type="button"
              className="border w-full h-12 flex items-center md-w-1/2 px-2 md:ml-1 rounded-md text-white"
              onClick={facebookAuth}
              style={{ backgroundColor: "#527BCB" }}
            >
              <img src={facebookIcon} className="w-5 h-5" />
              <div className="font-bold ml-2 text-xs truncate overflow-ellipsis">
                {state.hasAccount
                  ? "Sign in with Facebook"
                  : "Sign up with Facebook"}
              </div>
            </button>
          </div>

          <div className="flex justify-between items-center mt-8">
            <div className="border flex-1" />
            <div className="text-gray-600 px-2">or</div>
            <div className="border flex-1" />
          </div>

          <div className="flex w-full flex-col mt-4">
            <ZInput
              placeholder="Email Address"
              className="w-full h-12"
              type="email"
              name="email"
              onChange={onChangeText}
              value={state.email}
              autoComplete="new-password"
            />
            <ZInput
              placeholder="Password"
              containerClassName="mt-4"
              className="w-full h-12"
              type="password"
              name="password"
              onChange={onChangeText}
              value={state.password}
            />

            <Transition
              from={{
                opacity: 0,
                width: "100%",
                height: "0px",
                transform: "scale(0)",
              }}
              enter={{
                opacity: 1,
                width: "100%",
                height: "72px",
                transform: "scale(1)",
              }}
              leave={{
                opacity: 0,
                width: "100%",
                height: "0px",
                transform: "scale(0)",
              }}
              items={!state.hasAccount}
            >
              {(styles, items) =>
                items && (
                  <animated.div style={styles}>
                    <ZInput
                      placeholder="Confirm Password"
                      className="w-full h-12 transition-all"
                      containerClassName="mt-4 mb-4"
                      type="password"
                      name="confirmPassword"
                      onChange={onChangeText}
                      value={state.confirmPassword}
                    />
                  </animated.div>
                )
              // eslint-disable-next-line react/jsx-curly-newline
              }
            </Transition>
          </div>

          <button
            type="button"
            className="bg-zimx-indigo-dark h-12 text-white w-full rounded-md font-extrabold mt-4"
            onClick={state.hasAccount ? loginWithEmail : createWithEmail}
            style={{ backgroundColor: "#765992" }}
          >
            {state.hasAccount ? "Sign In" : "Register"}
          </button>

          <div
            className="w-24 h-24  rounded-full absolute -right-24 -top-40 bg-green-500"
            style={{ filter: "blur(20px)" }}
          />
          <div
            className="w-12 h-12 right-4 -top-16 rounded-full absolute bg-indigo-800 blur-3xl"
            style={{ filter: "blur(20px)" }}
          />

          <div
            className="w-12 h-12 right-12 -bottom-20 rounded-full absolute blur-3xl bg-indigo-800"
            style={{ filter: "blur(20px)" }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignInScreen;
