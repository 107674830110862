import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword, User, updateProfile } from "firebase/auth";
import { ZInput } from "../../components/common/input/ZInput";
import "./AccountDetails.styles.css";
import vault from '../../assets/icons/vault.png';
import { Button } from "../../components/common/button/button";
import { Modal } from "../../components/common/modal/Modal.component";
import { ZNav } from "../../components/common/nav/ZNav";
import { useAccount } from "./useAccount";

const initialState = { password: "", confirmPassword: "", newPassword: "", displayName: "" };

interface UpdateParameters extends User {
  password: string;
  confirmPassword: string;
  newPassword: string;
  displayName: string;
}

export const AccountDetails: React.FC<{}> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isNameModalOpen, setIsNameModalOpen] = useState<boolean>(false);
  const [state, setState] = useState <Partial<UpdateParameters>>(initialState);
  const { onUpdateUser, onDeleteUser, user } = useAccount();

  const onToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setState({ ...state, ...user } as UpdateParameters);
  }, [user]);

  const onToggleDisplayModal = () => {
    setIsNameModalOpen(((prevState) => !prevState));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const onUpdatePassword = async () => {
    const { password, newPassword, confirmPassword } = state as UpdateParameters;

    if (newPassword === confirmPassword && newPassword.length >= 8 && user?.email) {
      const authCredential = EmailAuthProvider.credential(user.email, password);
      reauthenticateWithCredential(user, authCredential).then((loggedInUser) => {
        updatePassword(loggedInUser.user, newPassword).then(() => {
          toast.success("Updated password successfully");
          setState({ ...state, ...initialState });
        }).catch(() => {
          throw new Error("Failed to update password");
        });
      }).catch(() => {
        toast.error("Failed to update password");
      });
    } else {
      toast.error("Password should be at least 8 characters long");
    }
  };

  const onUpdateDisplayName = async () => {
    if (user?.email && state?.password!) {
      const authCredential = EmailAuthProvider.credential(user.email, state?.password!);
      reauthenticateWithCredential(user, authCredential).then(async (loggedInUser) => {
        await updateProfile(loggedInUser.user, {
          displayName: state.displayName,
        }).then(() => {
          setState({ ...state, ...initialState });
        });
        await onUpdateUser({ name: state.displayName!, displayName: state.displayName });
      });
    }
  };

  return (
    <div className="account-details-container">
      <ZNav hideOptions />
      <div className="max-w-screen-lg w-full flex mt-12 p-4">
        <div className="hidden md:flex w-1/2">
          <img src={vault} draggable={false} />
        </div>
        <div className="w-full md:w-1/2">

          <ZInput value={user?.email || ""} label="Email" disabled onChange={() => null} />
          <div className="mt-4">
            <Button onClick={onToggleDisplayModal} title="Update display name" />
          </div>

          { user?.providerData?.find((provider) => provider.providerId === "password") && (
            <div className="mt-4">
              <Button onClick={onToggle} title="Update password" />
            </div>
          )}

          <div className="mt-4">
            <Button variant="error" onClick={onDeleteUser} title="Delete account" />
          </div>
        </div>
      </div>

      <Modal isOpen={isOpen} onToggle={onToggle} title="Update password">
        <ZInput label="Current password" value={state.password} name="password" placeholder="Enter your current password here" onChange={onChange} />
        <div className="mt-4">
          <ZInput label="New password" value={state.newPassword} name="newPassword" placeholder="Enter your new password here" onChange={onChange} />
        </div>
        <div className="mt-4">
          <ZInput label="Confirm password" value={state.confirmPassword} name="confirmPassword" placeholder="Retype your new password here" onChange={onChange} />
        </div>
        <div className="flex-1" />
        <div className="mt-4">
          <Button onClick={onUpdatePassword} title="Update password" />
        </div>
      </Modal>

      <Modal isOpen={isNameModalOpen} onToggle={onToggleDisplayModal} title="Update display name">
        <ZInput label="Display name" value={state.displayName} name="displayName" placeholder="" onChange={onChange} />
        <div className="mt-4">
          <ZInput label="Password" value={state.password} name="password" placeholder="Enter your new password here" onChange={onChange} />
        </div>
        <div className="flex-1" />
        <div className="mt-4">
          <Button onClick={onUpdateDisplayName} title="Update display name" />
        </div>
      </Modal>
    </div>
  );
};
