import { ProductGroup } from "../reducers/shop.reducer";
import { fetchFactory, RequestMethods } from "./fetchFactory";

type ISearchParams = {
  text: string;
};

export const findProduct = (queryParams: ISearchParams) => async (): Promise<
  ProductGroup[]
> => {
  const query = new URLSearchParams(queryParams);
  const response = await fetchFactory({
    endpoint: `/api/search/products?${query.toString()}`,
    method: RequestMethods.GET,
  });

  return response;
};
