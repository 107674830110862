import React from "react";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { ZSelectProps } from "./ZSelect.types";

const DropdownIndicator = (props: any) =>
  components.DropdownIndicator && (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon
        icon={faCaretDown}
        color={props.selectProps.menuIsOpen ? "#433054" : "#9ca3af"}
        size="2x"
      />
    </components.DropdownIndicator>
  );

export const ZSelect: React.FC<ZSelectProps> = ({
  onSelect,
  options,
  value,
  placeholder,
  containerClassName,
  disabled,
}) => {
  const selectedValue = options?.find?.((option) => option.value === value);

  return (
    <div className={containerClassName}>
      <Select
        isDisabled={disabled}
        options={options}
        onChange={(option: any) => onSelect(option)}
        placeholder={placeholder}
        styles={{
          control: (provided, controlState) => ({
            ...provided,
            backgroundColor: "#f2f2fe",
            borderColor: controlState.isFocused ? "#433054" : "#e5e7eb",
            paddingLeft: 16,
            paddingRight: 16,
            borderWidth: 2,
            height: 48,
            boxShadow: "0 !important",
            "&:hover": {
              borderColor: "#433054",
            },
            fontWeight: "bold",
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#9ca3af",
          }),
        }}
        value={selectedValue}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
      />
    </div>
  );
};
