export const objectsAreEqual = (
  originalData: { [key: string]: string | number },
  comparator: { [key: string]: string | number },
): boolean => {
  // get the keys of the comparator
  const keys = Object.keys(comparator);

  const comparison = keys.every((key) => originalData[key] === comparator[key]);
  return comparison;
};
