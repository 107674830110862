import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import gradientSVG from "../../assets/images/shop/variation-gradient.svg";
import generateVariations from "./variationGenerator";
import { objectsAreEqual } from "./objectCompare";
import { Button } from "../common/button/button";

const VariationCard: React.FC<{
  label: string;
  onSelected(label: string): void;
}> = ({ label, onSelected }) => {
  const [state, setState] = useState({ active: false });

  const onButtonClick = () => {
    setState({ ...state, active: !state.active });
    onSelected(label);
  };

  // animate the background color on click
  const animatedBackgroundColor = useSpring({
    to: {
      backgroundColor: state.active ? "rgba(177, 150, 270, 0.7)" : "white",
    },
  });
  // animate text color
  const animatedTextColor = useSpring({
    color: state.active ? "white" : "#433054",
  });

  return (
    <>
      <animated.div
        className="variation"
        style={animatedBackgroundColor as any}
        onClick={onButtonClick}
        role="button"
      >
        <div className="variation-design-container">
          <div className="variation-design-small-circle" />
          <div className="variation-design-smaller-circle" />
          <div className="variation-design-smallest-circle" />
        </div>
        <div className="variation-label-container">
          <animated.p
            className="variation-label"
            style={animatedTextColor as any}
          >
            {label}
          </animated.p>
        </div>
        <div className="gradient-container">
          <img src={gradientSVG} alt="" className="gradient-svg" />
          <div className="check-mark-container" />
        </div>
      </animated.div>
    </>
  );
};

const PricingRow: React.FC<{
  variationObject: { [key: string]: string };
  index: number;
  addVariationPrice(dependency: { [key: string]: string }, price: number): void;
}> = ({ variationObject, index, addVariationPrice }) => {
  const onChangePrice = (price: string) => {
    const priceNum: number = Number(price);
    addVariationPrice(variationObject, priceNum);
  };

  return (
    <div
      style={{
        backgroundColor: index % 2 === 0 ? "#f1ebfd" : "transparent",
        display: "grid",
        gridTemplateColumns: `repeat( ${Object.keys(variationObject).filter(
          (key) => key !== "price",
        ).length + 1}, 1fr)`,
        placeItems: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      {Object.keys(variationObject)
        .filter((key) => key !== "price")
        .map((value) => {
          const component = index === 0 && (
            <p style={{ fontWeight: "bold" }}>{value}</p>
          );
          return component;
        })}

      {index === 0 && <p style={{ fontWeight: "bold" }}>Price</p>}
      {Object.keys(variationObject)
        .filter((key) => key !== "price")
        .map((value: string) => (
          <div>
            <p className="pricing-text">{variationObject[value]}</p>
          </div>
        ))}
      <input
        type="number"
        className="w-20 h-10 text-right bg-white border-2 focus:border-zimx-indigo-dark rounded-md focus:outline-none"
        value={variationObject.price}
        onChange={(e) => onChangePrice(e.target.value)}
      />
    </div>
  );
};

export const AddPricingComponent: React.FC<{
  productVariations: Map<string, string[]>;
  variations: string[];
  currentPrices: { [key: string]: any }[];
  onSubmit: Function;
}> = ({ productVariations, variations, currentPrices, onSubmit }) => {
  const history = useHistory();
  const [pricingDependency, setPricingDependency] = useState<
    string[] & number[]
  >([]);
  const [pricing, setPricing] = useState<{ [key: string]: any }[]>([]);

  useEffect(() => {
    setPricing(currentPrices);
  }, [currentPrices]);

  const addPricingDependency = (label: string & number) => {
    let temp: string[] & number[] = [];
    if (!pricingDependency.includes(label)) {
      temp = Array.from(new Set([...pricingDependency, label]));
    } else {
      temp = pricingDependency.filter((item) => item !== label) as string[] &
        number[];
    }

    const variationList = generateVariations(temp, productVariations);
    setPricing(variationList);
    setPricingDependency(temp);
  };

  const addVariationPrice = (
    dependency: { [key: string]: string },
    price: number,
  ): void => {
    const updatedPricingDependency = pricing.map((priceDep) => {
      const shouldUpdate = objectsAreEqual(priceDep, dependency);
      if (shouldUpdate) {
        return { ...priceDep, price };
      }
      return priceDep;
    });

    setPricing(updatedPricingDependency);
  };

  const onSubmitPrices = () => {
    // dispatch({ type: ADD_PRICED_PRODUCTS, payload: pricingDependency })
    onSubmit(pricing);
    history.push("/shop/products/add/quantity");
  };

  return (
    <div className="product-details-container">
      <h2 className="text-gray-600 text-lg font-bold font-round ml-4 mb-4">
        Add Product Prices
      </h2>

      <div className="grid-cols-2">
        {variations.map((variation: string) => (
          <VariationCard
            label={variation}
            onSelected={(label: string & number) => addPricingDependency(label)}
          />
        ))}
      </div>

      <div style={{ marginTop: 20 }}>
        {pricing.map((dependency: any, index: number) => (
          <PricingRow
            variationObject={dependency}
            index={index}
            addVariationPrice={addVariationPrice}
          />
        ))}
      </div>
      <div className="fill" />

      <Button
        onClick={onSubmitPrices}
        title="Next"
      />

    </div>
  );
};
