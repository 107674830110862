import React, { useEffect, useState } from "react";
import { onAuthStateChanged, getAuth, User } from "firebase/auth";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ZLoader } from "../loading/ZLoader";
import { logoutUser } from "../../../redux/actions/user.actions";

interface ProtectedRouteProps {
  path?: string;
  exact?: boolean;
}

export const useFirebaseAuth = () => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>();
  const auth = getAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    const listener = onAuthStateChanged(auth, (user) => {
      setLoggedIn(!!user);
      if (!user) {
        dispatch(logoutUser());
      } else {
        setCurrentUser(user);
      }
      setLoading(false);
    });

    return listener;
  }, [auth]);

  return {
    loggedIn,
    loading,
    user: currentUser,
  };
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  path,
  children,
}): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { loggedIn, loading } = useFirebaseAuth();
  const navigateToAuth = () => {
    if (location.pathname === path) {
      history.replace("/authenticate");
    }
  };
  useEffect(() => {
    if (!loggedIn && !loading) {
      dispatch(logoutUser());
      navigateToAuth();
    }
  }, [loggedIn, loading]);

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <ZLoader />
      </div>
    );
  }

  return <Route path={path}>{children}</Route>;
};
