import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import {
  fetchFactory,
  RequestMethods,
} from "../../../redux/actions/fetchFactory";
import { RootState } from "../../../redux/store";
import { Town } from "./Towns.types";

export const useTowns = (
  cityId?: string,
): {
  towns: Town[];
  loading: boolean;
  error: boolean;
  onDeleteTown: (countryId: string) => void;
  onUpdateTown: (town: Town) => void;
  // eslint-disable-next-line function-paren-newline
  onAddTown: (town: Town) => void;
} => {
  const reduxAccountState = useSelector((state: RootState) => state.user);

  const { data = [], isLoading, isError, isLoadingError } = useQuery<Town[]>(
    `towns ${cityId}`,
    async () =>
      fetchFactory({
        endpoint: `/api/towns/${cityId ? `/city/${cityId}` : ""}`,
        method: RequestMethods.GET,
      }),
  );

  const deleteTown = useMutation(
    "towns",
    async (townId: string) =>
      fetchFactory({
        endpoint: `/api/towns/${townId}`,
        method: RequestMethods.DELETE,
        successMessage: "Deleted country successfully",
        errorMessage: "Failed to delete country",
        token: reduxAccountState.token,
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const addTown = useMutation(
    async (town: Town) =>
      fetchFactory({
        endpoint: `/api/towns`,
        method: RequestMethods.POST,
        body: town,
        successMessage: "Added town succesfully",
        token: reduxAccountState.token,
        errorMessage: "Failed to add town",
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const updateTown = useMutation(
    "towns",
    async (town: Town) =>
      fetchFactory({
        endpoint: `/api/towns/${town.id}`,
        method: RequestMethods.PUT,
        successMessage: "Deleted town successfully",
        errorMessage: "Failed to delete town",
        token: reduxAccountState.token,
        body: town,
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const onDeleteTown = (townId: string) => deleteTown.mutate(townId);

  const onUpdateTown = (town: Town) => {
    updateTown.mutate(town);
  };

  const onAddTown = (town: Town) => {
    addTown.mutate(town);
  };

  return {
    towns: data,
    loading: isLoading,
    error: isError || isLoadingError,
    onAddTown,
    onDeleteTown,
    onUpdateTown,
  };
};
