import React from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ModalProps } from "./Modal.types";
import "./Modal.styles.css";

export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  onToggle,
  title,
  className,
  titleClassName,
  dividerClassName,
  dismissOnTouchOutSide,
}): JSX.Element => (
  <ReactModal
    className={`modal-container ${className}`}
    onRequestClose={onToggle}
    shouldCloseOnEsc
    shouldCloseOnOverlayClick={dismissOnTouchOutSide}
    overlayClassName="modal-overlay"
    isOpen={isOpen}
    appElement={document?.querySelector("#root") as HTMLElement}
    closeTimeoutMS={500}
  >
    <div className={`modal-title-container ${titleClassName}`}>
      <div>{title}</div>
      <button type="button" className="close-button" onClick={onToggle}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
    <div className={`modal-divider ${dividerClassName}`} />
    {children}
  </ReactModal>
);
