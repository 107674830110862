import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import "../css/homeScreen.css";
import { useHistory } from "react-router-dom";
import { RootState } from "../../redux/store";
import { apiGetCart } from "../../redux/actions/cart.actions";
import { ScreenContainer } from "../common/screenContainer";
import { useHomeScreenProducts } from "../shop/hooks/useProducts";
import { ProductItem } from "../common/ProductItem";
import { ProductGroup } from "../../redux/reducers/shop.reducer";
import { useShop } from "../shop/hooks/useShop";

export const HomeScreen = () => {
  const user = useSelector((state: RootState) => state.user);
  // fetch user shop state
  const { loading: shopLoading } = useShop();
  const { products = [], loading } = useHomeScreenProducts();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(apiGetCart());
    }
  }, [user]);

  const onClickProduct = (product: ProductGroup) => {
    history.push(`/search/${product?._id}`);
  };

  const renderProducts = (): JSX.Element[] =>
    products?.map?.((product) => (
      <ProductItem product={product} key={product?._id} onClick={onClickProduct} />
    ));

  return (
    <ScreenContainer loading={loading || shopLoading} title="Products">
      <div className="w-full grid grid-cols-4 gap-1 md:gap-4 items-start mt-8 ">
        {renderProducts()}
      </div>
    </ScreenContainer>
  );
};
