export interface ActionInterface {
  type: string;
  payload?: any;
}

// user actions
export const API_REGISTER_USER = "API_REGISTER_USER";
export const API_LOGIN_USER = "API_LOGIN_USER";
export const API_LOGOUT_USER = "API_LOGOUT_USER";

// shop actions
export const API_GET_USER_SHOP = "API_GET_USER_SHOP";

// product types
export const API_GET_PRODUCTS = "API_GET_PRODUCTS";

// cart types
export const API_GET_CART = "API_GET_CART";
