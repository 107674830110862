import React from "react";
import { Address, useAddressess } from "../../screens/Address";
import { City } from "../admin/cities/Cities.types";
import { useCities } from "../admin/cities/useCities";
import { Country } from "../admin/countries/Countries.types";
import { useCountries } from "../admin/countries/useCountries";
import { Town } from "../admin/towns/Towns.types";
import { useTowns } from "../admin/towns/useTowns";
import { Button } from "../common/button/button";
import { ZInput } from "../common/input/ZInput";
import { Modal } from "../common/modal/Modal.component";
import { ZSelect } from "../common/select/ZSelect.component";
import { AddressModalProps } from "./AddressModal.types";

export const AddressModal: React.FC<AddressModalProps> = ({
  onSelect,
  onChange,
  address,
  onToggle,
  isOpen,
  isShop = false,
  title = "Add Delivery Address",
  onClickSave,
}) => {
  const { countries } = useCountries();
  const { onAddAddress } = useAddressess();

  const { cities } = useCities((address?.country as Country)?.id);

  const { towns } = useTowns((address?.city as Town)?.id);

  const dialCode = (): string => {
    if (countries) {
      const selectedCountry = countries.find(
        (country) => country.id === address?.country,
      );

      return selectedCountry ? `+${selectedCountry?.dialCode.toString()}` : "";
    }
    return "";
  };

  return (
    <Modal isOpen={isOpen} onToggle={onToggle} title={title}>
      {!isShop && (
        <>
          <ZInput
            placeholder="First name"
            name="firstName"
            containerClassName="mb-4"
            value={address?.firstName}
            onChange={onChange}
          />
          <ZInput
            placeholder="Last name"
            name="lastName"
            containerClassName="mb-4"
            value={address?.lastName}
            onChange={onChange}
          />
        </>
      )}

      <ZSelect
        onSelect={
          (option) => onSelect("country", option?.value as string)
          // eslint-disable-next-line react/jsx-curly-newline
        }
        options={countries.map((country: Country) => ({
          label: country.name,
          value: country.id,
        }))}
        value={(address?.country as Country)?.id}
        containerClassName="mb-4"
        placeholder="Select country"
      />

      <ZSelect
        onSelect={(option) => onSelect("city", option?.value as string)}
        options={cities.map((city: City) => ({
          label: city.name,
          value: city.id,
        }))}
        containerClassName="mb-4"
        placeholder="Select City"
        value={(address?.city as City)?.id}
        disabled={!address?.country}
      />

      <ZSelect
        onSelect={(option) => onSelect("town", option?.value as string)}
        options={towns.map((town) => ({
          label: town.name,
          value: town.id,
        }))}
        containerClassName="mb-4"
        disabled={!address?.country || !address?.city}
        value={(address?.town as Town)?.id as string}
        placeholder="Select Town"
      />

      <ZInput
        placeholder="Street address"
        name="streetAddress"
        containerClassName="mb-4"
        value={address?.streetAddress}
        onChange={onChange}
      />
      <ZInput
        placeholder="Phone number"
        name="phoneNumber"
        containerClassName="mb-4"
        value={address?.phoneNumber}
        onChange={onChange}
        leftElement={
          // eslint-disable-next-line react/jsx-wrap-multilines
          dialCode() ? (
            <div className="h-12 ml-4 flex items-center justify-center font-bold">
              {dialCode()}
            </div>
          ) : null
        }
      />
      <div className="mt-4 flex flex-1 items-end">
        <Button title="Save" onClick={onClickSave || (() => onAddAddress(address as Address))} />
      </div>
    </Modal>
  );
};
