import { faBars, faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Manager, Popper, Reference } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ReactSVG } from "react-svg";
import { getAuth } from "firebase/auth";
import { IUser } from "../../../redux/reducers/user.reducer";
import { RootState } from "../../../redux/store";
import { ZSearchInput } from "../input/ZSearchInput";
import userIcon from "../../../assets/icons/user.svg";
import purchaseHistoryIcon from "../../../assets/icons/purchase-history.svg";
import createShopIcon from "../../../assets/icons/create-shop.svg";
import logOutIcon from "../../../assets/icons/logout.svg";
import cartIcon from "../../../assets/icons/cart.svg";
import VeelyLogo from "../../../assets/icons/veely.svg";
import { MenuListItem } from "../menu/menuListItem";
import { Modal } from "../modal/Modal.component";
import { Button } from "../button/button";
import { logoutUser } from "../../../redux/actions/user.actions";
import { useCart } from "../../cart/useCart";

const MenuList = ():JSX.Element => {
  const history = useHistory();
  const reduxUserState = useSelector((state: RootState) => state.user);
  const reduxShopState = useSelector((state: RootState) => state.shop);
  const dispatch = useDispatch();

  const onClickCreateShop = () => {
    history.push("/create-shop");
  };

  const onClickMyAccount = () => {
    history.push("/account-details");
  };

  const onClickLogout = () => {
    getAuth().signOut();
    history.replace("/authenticate");
    dispatch(logoutUser());
  };

  const onClickPurchaseHistory = () => {
    history.push("/purchase-history");
  };

  const onClickGoToShop = () => {
    history.push('/shop');
  };

  return reduxUserState?.name || reduxUserState.email ? (
    <>
      <MenuListItem
        label="My Account"
        leftIcon={<ReactSVG src={userIcon} />}
        onClick={onClickMyAccount}
      />
      <MenuListItem
        label="Purchase History"
        leftIcon={<ReactSVG src={purchaseHistoryIcon} />}
        onClick={onClickPurchaseHistory}
      />
      {reduxShopState?.id ? (
        <MenuListItem
          label="Create Shop"
          leftIcon={<ReactSVG src={createShopIcon} />}
          onClick={onClickCreateShop}
        />
      )
        : (
          <MenuListItem
            label="My shop"
            leftIcon={<ReactSVG src={createShopIcon} />}
            onClick={onClickGoToShop}
          />
        )}
      <MenuListItem
        label="Logout"
        className="text-red-500"
        leftIcon={<ReactSVG src={logOutIcon} />}
        onClick={onClickLogout}
      />
    </>
  ) : <Button title="Login" onClick={() => history.push("/authenticate")} />;
};

const UserOptions = () => {
  const [user, setUser] = useState<IUser>({} as IUser);
  const [showPopper, setShowPopper] = useState<boolean>(false);
  const reduxUserState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    setUser(reduxUserState);
  }, [reduxUserState]);

  const togglePopper = () => {
    setShowPopper((prevState) => !prevState);
  };
  return (
    <>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              className="flex items-center"
              ref={ref}
              onClick={() => togglePopper()}
            >
              <div className="text-gray-800 font-bold mr-2">
                {user?.name?.split(" ")[0]}
              </div>
              {user.picture ? (
                <img
                  src={user?.picture}
                  alt=""
                  className="w-12 h-12 object-contain rounded-full bg-gray-600"
                />
              ) : (
                <div className="w-12 h-12 flex items-center justify-center rounded-full border-2 border-zimx-indigo-dark">
                  <ReactSVG src={userIcon} className="text-2xl " />
                </div>
              )}
            </div>
          )}
        </Reference>
        {showPopper && (
          <Popper placement="bottom-end">
            {({ ref, style }) => (
              <div
                ref={ref}
                style={{ ...style, backgroundColor: "#fff" }}
                className="shadow-lg border-gray-100 py-4 mt-3 md:mt-4  rounded-md  absolute md:relative md:w-60 w-full  z-10"
              >
                <MenuList />
              </div>
            )}
          </Popper>
        )}
      </Manager>
    </>
  );
};

interface ZNavProps {
  hideOptions?: boolean;
}

export const ZNav: React.FC<ZNavProps> = ({ hideOptions }): JSX.Element => {
  const { cart } = useCart();
  const reduxUserState = useSelector((state: RootState) => state.user);
  const [menuModalVisible, setMenuModalVisible] = useState(false);
  const history = useHistory();

  const toggleMenuModal = () => {
    setMenuModalVisible((prevState) => !prevState);
  };

  return (
    <nav className="flex w-full flex-col  md:flex-row bg-white py-2 justify-between items-center shadow-md z-50 sticky top-0 min-h-[68px]">
      <div className="font-rounded hidden md:flex text-zimx-indigo-dark font-bold text-3xl px-4 w-1/6">
        <ReactSVG src={VeelyLogo} />
      </div>

      {/* Display menu on small screens */}
      <div className="flex w-full md:hidden justify-between px-4">
        <div className="font-rounded md:hidden flex text-zimx-indigo-dark font-bold text-3xl">
          <ReactSVG src={VeelyLogo} />
        </div>

        {
          !hideOptions && (
            <div className="md:hidden flex justify-between text-zimx-indigo-dark">
              <a href="/cart" className="relative mx-4 cursor-pointer">
                <FontAwesomeIcon
                  icon={faShoppingBag}
                  className="text-zimx-indigo-dark text-3xl"
                />

                {cart?.length && (
                  <div className="absolute flex items-center justify-center text-white text-xs bg-red-500 -top-2 -right-3 w-5 h-5 rounded-full">
                    {cart?.length}
                  </div>
                )}
              </a>

              <FontAwesomeIcon
                icon={faBars}
                className="text-zimx-indigo-dark text-3xl"
                onClick={toggleMenuModal}
              />

            </div>
          )
        }

        <Modal onToggle={toggleMenuModal} isOpen={menuModalVisible} title="Profile">
          <MenuList />
        </Modal>
      </div>
      {!hideOptions && (
        <>
          <ZSearchInput placeholder="Find Product or Shop" className="font-bold" />
          {reduxUserState.name || reduxUserState?.email ? (
            <div className="w-1/6 hidden md:flex justify-end px-4 text-zimx-indigo-dark">
              <a
                href="/cart"
                className="relative h-12 w-12 border-2 mr-4 hover:text-zimx-indigo-dark border-zimx-indigo-dark rounded-full flex items-center justify-center text-zimx-indigo-dark"
              >
                <ReactSVG src={cartIcon} />

                {cart?.length ? (
                  <div className="absolute flex items-center justify-center text-white text-xs bg-red-500 -top-2 -right-3 w-5 h-5 rounded-full">
                    {cart?.length}
                  </div>
                ) : null}
              </a>

              <UserOptions />
            </div>
          ) : (
            <div className="w-1/6 px-4 hidden md:flex">

              <Button title="Login" onClick={() => history.push("/authenticate")} />
            </div>
          )}
        </>
      )}
    </nav>
  );
};
