import { faEdit, faPen, faStore, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from 'uuid';
import { Button } from "../../common/button/button";
import { ZInput } from "../../common/input/ZInput";
import { Modal } from "../../common/modal/Modal.component";
import { ScreenContainer } from "../../common/screenContainer";
import { ImageCroppingModal } from "../../user/createShop";
import { Brand, BrandModalProps } from "./Brands.types";
import { useBrands } from "./useBrands";

const BrandDetailsModal: React.FC<BrandModalProps> = ({
  isOpen,
  toggleModal,
  brand,
}) => {
  const [state, setState] = useState<Brand | undefined>(brand);
  const [image, setImage] = useState<string>("");
  const [brandModalOpen, setBrandModalOpen] = useState<boolean>(false);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);

  useEffect(() => {
    setState(brand);
  }, [brand]);

  const toggleBrandModal = () => {
    setBrandModalOpen((prevState) => !prevState);
  };

  const { onUpdateBrand, onAddBrand } = useBrands();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name && value) {
      setState({ ...state, [name]: value } as Brand);
    }
  };

  const onCropImage = (croppedImageFile: Blob) => {
    if (croppedImageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(croppedImageFile);
      reader.onloadend = () => {
        setImage(reader.result as string);
        setImageBlob(croppedImageFile);
      };
    }
  };

  const uploadImage = async () => {
    if (imageBlob) {
      const uploadUrl = `brands/${uuid()}.jpg`;

      const imageRef = ref(getStorage(), uploadUrl);
      const snapShot = await uploadBytes(imageRef, imageBlob);
      return getDownloadURL(snapShot.ref);
    }
    return null;
  };

  const onSelectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      toggleBrandModal();
    }
  };

  const onClickSave = async () => {
    const logoUrl = await uploadImage();
    if (state?.name && (state.logo || logoUrl)) {
      await onAddBrand({ name: state?.name, logo: logoUrl || state.logo });
    }
  };

  const onClickUpdate = async () => {
    const logoUrl = await uploadImage();
    if (state?.name && (state.logo || logoUrl)) {
      await onUpdateBrand({ name: state?.name, logo: logoUrl || state.logo, id: state.id });
    }
  };

  return (
    <Modal
      onToggle={toggleModal}
      title={brand?.id ? "Edit brand" : "Add brand"}
      isOpen={isOpen}
      className="overflow-hidden"
      dismissOnTouchOutSide={false}
    >

      <div className="mb-4">
        <label
          className="cursor-pointer relative text-gray-700 mb-4"
          htmlFor="logo"
        >
          <div className="absolute bottom-0 right-0 bg-gray-400 w-8 h-8 shadow-md items-center justify-center flex rounded-full">

            <FontAwesomeIcon icon={faPen} />
          </div>
          {state?.logo || image.trim() !== "" ? (
            <img
              src={state?.logo || image}
              className="h-32 w-32 rounded-full bg-gray-700 bg-cover"
            />
          ) : (
            <div className="h-32 w-32 rounded-full bg-gray-300 items-center justify-center flex bg-cover">
              <FontAwesomeIcon icon={faStore} size="2x" />
            </div>
          )}

        </label>
        <input
          type="file"
          className="hidden"
          accept="image/*"
          name="logo"
          id="logo"
          onChange={onSelectImage}
        />
        <ZInput
          placeholder="Brand name"
          name="name"
          value={state?.name}
          onChange={onChange}
        />
        <ImageCroppingModal
          image={image}
          toggleModal={toggleBrandModal}
          isOpen={brandModalOpen}
          onCrop={onCropImage}
        />
      </div>
      <div className="flex flex-1 items-end">
        <Button
          title={state?.id ? "Update" : "Save"}
          onClick={() => {
            if (state?.id) {
              onClickUpdate();
            } else {
              onClickSave();
            }
            toggleModal();
          }}
        />
      </div>
    </Modal>
  );
};

export const Brands: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [selectedBrand, setSelectedBrand] = useState<Brand | undefined>();
  const toggleModal = (item: Brand | undefined) => {
    setSelectedBrand(item);
    setIsModalOpen((prevState) => !prevState);
  };

  const { brands, loading, error, onDeleteBrand } = useBrands();
  const renderBrands = () =>
    brands?.map?.(
      (brand) =>
        brand && (
          <tr className="hover:bg-gray-100">
            <td>{brand.name}</td>

            <td>
              <button
                type="button"
                onClick={() => {
                  toggleModal(brand);
                }}
              >
                <FontAwesomeIcon
                  className="text-zimx-indigo-dark text-xl"
                  icon={faEdit}
                />
              </button>
            </td>
            <td>
              <button type="button" onClick={() => onDeleteBrand(brand.id!)}>
                <FontAwesomeIcon
                  className="text-red-500 text-xl"
                  icon={faTrash}
                />
              </button>
            </td>
          </tr>
        ),
    );

  return (
    <ScreenContainer title="Brands" loading={loading}>
      <table className="table table-auto max-w-screen-xl mt-8 mb-8 w-full">
        <thead className="text-left">
          <th>Brand name</th>
          <th>{}</th>
          <th>{}</th>
        </thead>
        <tbody>{brands && !loading && !error && renderBrands()}</tbody>
      </table>
      <Button title="Add Brand" onClick={() => toggleModal(undefined)} />
      <BrandDetailsModal
        isOpen={isModalOpen}
        toggleModal={() => toggleModal(undefined)}
        brand={selectedBrand}
      />
    </ScreenContainer>
  );
};
