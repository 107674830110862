import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { findProduct } from "../../redux/actions/search.actions";
import { ProductGroup } from "../../redux/reducers/shop.reducer";
import { ProductItem } from "../common/ProductItem";
import { ScreenContainer } from "../common/screenContainer";

export const SearchResults = () => {
  const [products, setProducts] = useState<ProductGroup[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const searchTerm = new URLSearchParams(location.search).get("text");
    if (searchTerm) {
      const getProducts = async () => {
        setIsLoading(true);
        const response: ProductGroup[] = ((await dispatch(
          findProduct({ text: searchTerm }),
        )) as unknown) as ProductGroup[];

        if (response) {
          const loadImages = response.map(
            // eslint-disable-next-line no-return-await
            async (product) => {
              const image = new Image();

              if (product?.imageUrls?.[0]) {
                const imageLoaded = new Promise((resolve, reject) => {
                  image.src = product?.imageUrls?.[0];
                  image.onload = () => resolve(image);
                  image.onerror = () => reject();
                });

                return imageLoaded;
              }
              return image;
            },
          );
          await Promise.all(loadImages);
          setProducts(response);
        }
        setTimeout(() => setIsLoading(false), 1000);
      };
      getProducts();
    }
  }, []);

  const onClickProduct = (product: ProductGroup) => {
    history.push(`/search/${product?._id}`);
  };

  const renderProducts = (): JSX.Element[] =>
    products?.map?.((product) => (
      <ProductItem key={product._id} product={product} onClick={onClickProduct} />
    ));

  return (
    <ScreenContainer loading={isLoading} title="Search results">
      <div className="w-full grid grid-cols-4 gap-1 md:gap-4 items-start mt-8">
        {renderProducts()}
      </div>
    </ScreenContainer>
  );
};
