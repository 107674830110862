import React from 'react';
import { Button } from '../../components/common/button/button';
import { ScreenContainer } from "../../components/common/screenContainer";

interface StatusPageProps {title: string, message: string, loading?: boolean}

export const StatusPage: React.FC<StatusPageProps> = ({ title, message, loading }): JSX.Element => (
  <ScreenContainer loading={loading} title={title}>
    <p className="text-lg text-blue-grey-700 font-medium mt-4">{message}</p>
    <div className="w-full flex items-center mt-16 sm:justify-start justify-center">
      <div className="max-w-sm w-full flex">

        <Button title="Go back" onClick={() => null} />
      </div>
    </div>
  </ScreenContainer>
);
