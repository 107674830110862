import { combineReducers, createStore, applyMiddleware } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { ShopReducer } from "./reducers/shop.reducer";
import { variationReducer } from "./reducers/product.reducers";
import { UserReducer } from "./reducers/user.reducer";
import { CartReducer } from "./reducers/cart.reducer";

const reducer = combineReducers({
  variations: variationReducer,
  user: UserReducer,
  shop: ShopReducer,
  cart: CartReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const enhancer = applyMiddleware(thunk);

export const store = createStore(persistedReducer, enhancer);
export type RootState = ReturnType<typeof store.getState>;
export const persistedStore = persistStore(store);
