/* eslint-disable function-paren-newline */
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  fetchFactory,
  RequestMethods,
} from "../../../redux/actions/fetchFactory";
import { ProductGroup } from "../../../redux/reducers/shop.reducer";
import { RootState } from "../../../redux/store";

export const useProducts = (
  shopId?: string,
): {
  products?: ProductGroup[];
  loading: boolean;
  error: boolean;
} => {
  const reduxAccountState = useSelector((state: RootState) => state.user);

  const { data = [], isLoading, isError, isLoadingError } = useQuery<
    ProductGroup[]
  >(
    `shop ${shopId}`,
    async () =>
      fetchFactory({
        method: RequestMethods.GET,
        endpoint: `/api/products/${shopId}`,
        errorMessage: "Failed to get shop details",
        token: reduxAccountState.token,
      }),
    { enabled: !!shopId },
  );

  return {
    products: data,
    loading: isLoading,
    error: isError || isLoadingError,
  };
};

export const useHomeScreenProducts = () => {
  const reduxAccountState = useSelector((state: RootState) => state.user);

  const { data = [], isLoading, isError, isLoadingError } = useQuery<
    ProductGroup[]
  >(`home-screen-products`, async () =>
    fetchFactory({
      method: RequestMethods.GET,
      endpoint: `/api/search/products/home-screen`,
      token: reduxAccountState.token,
    }),
  );

  return {
    products: data,
    loading: isLoading,
    error: isError || isLoadingError,
  };
};
