import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { fetchFactory, RequestMethods } from "../../redux/actions/fetchFactory";
import { RootState } from "../../redux/store";
import { Address } from "./Address.types";

export const useAddressess = (): {
  loading: boolean;
  addresses?: Address[];
  errors?: any;
  onAddAddress: (address: Address) => void;
} => {
  const user = useSelector((state: RootState) => state.user);
  const queryClient = useQueryClient();

  const { data: addresses, isLoading, isError, isLoadingError } = useQuery<
    Address[]
  >(
    `addresses`,
    async () =>
      fetchFactory({
        endpoint: "api/address",
        method: RequestMethods.GET,
        token: user.token,
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const addAddress = useMutation(
    "addresses",
    async (address: Address) =>
      fetchFactory({
        endpoint: "/api/address",
        method: RequestMethods.POST,
        token: user.token,
        errorMessage: "Failed to add address",
        successMessage: "Added address successfully",
        body: address,
      }),
    {
      onSuccess: (response) => {
        queryClient.setQueryData("addresses", response);
      },
    },
  );

  const onAddAddress = (address: Address) => {
    addAddress.mutate(address);
  };

  return {
    addresses,
    loading: isLoading,
    errors: isError || isLoadingError,
    onAddAddress,
  };
};
