/* eslint-disable no-return-assign */
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  useHistory,
} from "react-router-dom";
import { StatusPage } from "../../pages/StatusPage";
import {
  Product,
} from "../../redux/reducers/shop.reducer";
import { RootState } from "../../redux/store";
import AddProductScreen from "../products/addProductScreen";
import { useProducts } from "./hooks/useProducts";
import { useShop } from "./hooks/useShop";
import { ViewSingleProduct } from "./products/ViewSingleProduct";

export const ViewProducts = () => {
  const reduxShopState = useSelector(
    (reduxState: RootState) => reduxState.shop,
  );
  const { products = [] } = useProducts(reduxShopState?.id);
  const history = useHistory();

  const getQuantity = (items: Product[]) =>
    // eslint-disable-next-line arrow-body-style
    items.reduce((acc: number, currentValue: Product) => {
      const sum = acc + Number(currentValue.quantity);
      return sum;
    }, 0);

  const onClickProduct = (shopId: string, productId: string) => {
    history.push(`/shop/product/${shopId}/${productId}`);
  };

  const onClickAddProduct = () => {
    history.push("/shop/products/add");
  };

  const renderProducts = (): JSX.Element[] =>
    products?.map?.((product) => (
      <div
        onClick={() => onClickProduct(product.shop, product._id)}
        className="w-full h-full  overflow-hidden shadow-sm rounded-md hover:shadow-2xl bg-white cursor-pointer pb-2"
      >
        <img
          src={product.imageUrls[0]}
          className=" w-full bg-cover"
          alt=""
          style={{ aspectRatio: "1", borderColor: "white" }}
        />
        <div className="pt-3 px-2 tracking-widest">
          <img src={product?.brand?.logo} alt={product.brand?.name} className="w-8 h-8 rounded-full border-2" />
        </div>
        <div className="pb-1 pt-1 flex items-center px-3">
          <p className="font-rounded flex-1 overflow-hidden font-bold overflow-ellipsis text-gray-700">
            {product.name}
          </p>
          <p className="text-sm text-zimx-indigo-dark pl-1 font-bold">
            {`${getQuantity(product.products)}`}
          </p>
        </div>
      </div>
    ));

  return (
    <div className="w-full grid gap-8 md:grid-cols-4 max-w-screen-lg auto-rows-min grid-flow-row">
      <div
        className="w-full md:h-full  bg-zimx-indigo-100 opacity-75 hover:opacity-100 rounded-md shadow-md hover:shadow-xl cursor-pointer
        flex items-center justify-center"
        onClick={onClickAddProduct}
      >
        <FontAwesomeIcon icon={faPlus} className="text-4xl text-white " />
      </div>
      {renderProducts()}
    </div>
  );
};

export const Shop = () => {
  const reduxShopState = useSelector(
    (reduxState: RootState) => reduxState.shop,
  );

  const { loading } = useShop();

  return !reduxShopState.verified ? <StatusPage loading={loading} title="Shop verification" message="Your shop has not been verified yet, kindly reach out to us for more information." /> : (

    <div className="w-screen min-w-full min-h-screen overflow-x-hidden flex bg-gray-100 items-start relative">
      {/* Sidebar */}
      <div className="w-0 hidden md:flex flex-col md:w-1/5 shadow-2xl fixed left-0 top-0 bg-zimx-indigo-dark bg-opacity-10 h-full min-h-screen">
        <div className="w-full relative" style={{ aspectRatio: "1" }}>
          <img
            src={reduxShopState.logo}
            className="w-full"
            style={{ aspectRatio: "1" }}
          />
          <div className=" h-full absolute bottom-0 left-0 w-full from-transparent to-sidebar bg-gradient-to-b" />
        </div>
        <div className="py-2 px-4 w-full transition-all duration-300 border-b border-zimx-indigo-dark border-opacity-10 text-gray-700 bg-zimx-indigo-dark bg-opacity-10 hover:bg-opacity-80 hover:text-white font-rounded font-bold cursor-pointer">
          Products
        </div>
        <div className="py-2 px-4 w-full transition-all duration-300 border-b border-zimx-indigo-dark border-opacity-10 text-gray-700 bg-zimx-indigo-dark bg-opacity-10 hover:bg-opacity-80 hover:text-white font-rounded font-bold cursor-pointer">
          Orders
        </div>
        <div className="py-2 px-4 w-full transition-all duration-300 border-b border-zimx-indigo-dark border-opacity-10 text-gray-700 bg-zimx-indigo-dark bg-opacity-10 hover:bg-opacity-80 hover:text-white font-rounded font-bold cursor-pointer">
          Balance
        </div>

        <div className="flex flex-auto w-full" />
        <div className="py-2 px-4 self-end mb-16 text-gray-700 bg-red-900 bg-opacity-30 font-rounded font-bold fixed bottom-0 w-1/5 hover:bg-opacity-80 hover:text-white cursor-pointer">
          Log Out
        </div>
      </div>
      <div className="w-0 md:w-1/5" />
      {/* Body */}
      <div className="sm:w-full flex-1 md:w-4/5 min-h-screen p-4 flex justify-center">
        <div className="sm:w-full md:w-4/5  max-w-screen-lg flex justify-center flex-1">
          <Router>
            <Route exact path="/shop/products">
              <ViewProducts />
            </Route>

            <Route path="/shop/products/add">
              <AddProductScreen />
            </Route>

            <Route path="/shop/product/:shopId/:productId">
              <ViewSingleProduct />
            </Route>

            <Redirect from="/" to="/shop/products" />
          </Router>
        </div>
      </div>
    </div>
  );
};
