import React from 'react';
import { Product } from '../../../redux/reducers/shop.reducer';
import { ProductItemProps } from './ProductItem.types';
import "./ProductItem.css";

const getMinPrice = (items: Product[]) => {
  const minPrice = Math.min(...items.map((p) => Number(p.price)));
  return minPrice;
};

export const ProductItem: React.FC<ProductItemProps> = ({ product, onClick }) => (
  <div
    onClick={() => onClick(product)}
    className="product-item-container"
  >
    <img
      src={product.imageUrls[0]}
      className="product-item-img"
    />

    <div className="product-item-details-row">
      <div className="product-item-details-col">
        <img src={product?.brand?.logo} alt={product.brand?.name} className="w-8 h-8 rounded-full border-2" />

        <div className="product-item-details-brand-container">
          <p className="product-item-details-name">
            {product.name}
          </p>
        </div>
      </div>
      <p className="product-item-details-price">
        {`$${getMinPrice(product.products)}`}
      </p>
    </div>

  </div>
);
