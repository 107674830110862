import React from "react";
import { PurchaseHistoryProduct } from "./PurchaseHistory.types";

export const PurchaseHistoryItem: React.FC<{
  item: PurchaseHistoryProduct;
}> = ({ item }): JSX.Element => {
  const variation = item.productGroup.products.find(
    (product) => product._id === item.product,
  );

  return (
    <div className="w-full h-full flex items-start border-b py-4">
      <img
        src={item.productGroup.imageUrls[0]}
        alt=""
        className="w-1/5 rounded-md overflow-hidden"
        style={{ aspectRatio: "1" }}
        draggable={false}
      />
      <div className="flex flex-col w-full h-full flex-1 px-4">
        {/* Render name and price */}
        <div className="w-full flex">
          <div className="w-full flex items-center font-rounded font-bold text-gray-700 bg-gray-300 rounded-md">
            <p className="w-full py-2 px-4 flex items-center justify-between m-0 text-xl ">
              <p className="m-0">{item.productGroup.name}</p>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <p className="m-0 text-indigo-700">${variation?.price}</p>
            </p>
          </div>
        </div>

        {/* Render variations */}
        <div className="grid grid-flow-row grid-cols-3 mt-4">
          {Object.keys(variation!).map((productKey) => {
            const fixedCartProperties = [
              "_id",
              "createdAt",
              "price",
              "quantity",
              "updatedAt",
            ];

            if (!fixedCartProperties.includes(productKey)) {
              return (
                <div key={productKey}>
                  <button
                    type="button"
                    className={`w-full transition-all duration-300 rounded-md focus:outline-none text-center
                  hover:shadow-md cursor-pointer  
                  shadow-md py-2 px-1 overflow-ellipsis relative overflow-hidden 
                  hover:opacity-80 bg-zimx-indigo-100 text-white`}
                  >
                    <div className="absolute w-3 h-3 opacity-30 bg-green-400 rotate-45 rounded-sm top-2 left-1" />
                    <div className="absolute w-5 h-5 opacity-30 bg-indigo-400 rounded-full top-5 left-2" />
                    <div className="absolute w-14 h-14 opacity-30 bg-blue-500 rounded-full -right-7 -bottom-7" />
                    {(variation as Record<string, string>)[productKey]}
                  </button>
                </div>
              );
            }

            return null;
          })
          // eslint-disable-next-line react/jsx-curly-newline
          }
        </div>

        {/* Render quantities - add option to update quantites */}
        <div className="flex-1" />
        <div className="flex self-end mt-4 flex-col md:flex-row flex-wrap justify-between items-center md:mr-4">
          <p className="w-full h-full md:w-1/2 text-gray-700 font-bold text-lg mb-0 md:flex hidden items-center justify-center">
            Quantitity
          </p>

          <input
            type="text"
            className="h-8 w-8 border-2 border-gray-700 rounded-lg text-center bg-white text-gray-700 font-rounded font-bold"
            value={item.quantity}
            min={1}
            disabled
          />
        </div>
        <div className="flex-1" />
      </div>
    </div>
  );
};
