import React, { useState, useEffect } from "react";
import { animated, useSpring } from "react-spring";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import gradientSVG from "../../assets/images/shop/variation-gradient.svg";
import { Button } from "../common/button/button";

const VariationCard = ({ label, onSelected, variations }) => {
  const [state, setState] = useState({ active: false });

  const onButtonClick = () => {
    setState({ ...state, active: !state.active });
    onSelected(label);
  };

  // animate the background color on click
  const animatedBackgroundColor = useSpring({
    backgroundColor: state.active ? "rgba(177, 150, 270, 0.7)" : "white",
  });
  // animate text color
  const animatedTextColor = useSpring({
    color: state.active ? "white" : "#433054",
  });

  useEffect(() => {
    if (variations.includes(label)) {
      setState({ active: true });
    }
  }, [variations, label]);

  return (
    <>
      <animated.div
        className="variation"
        style={animatedBackgroundColor}
        onClick={onButtonClick}
        role="button"
      >
        <div className="variation-design-container">
          <div className="variation-design-small-circle" />
          <div className="variation-design-smaller-circle" />
          <div className="variation-design-smallest-circle" />
        </div>
        <div className="variation-label-container">
          <animated.p
            className="variation-label w-20 overflow-ellipsis"
            style={animatedTextColor}
          >
            {label}
          </animated.p>
        </div>
        <div className="gradient-container">
          <img src={gradientSVG} alt="" className="gradient-svg" />
          <div className="fill" />
        </div>
      </animated.div>
    </>
  );
};

const AddProductVariations = ({ onSubmitVariations, variations }) => {
  const [state, setState] = useState({
    variations: [],
    popularVariations: ["Size", "Color", "Weight", "Storage"],
  });

  useEffect(() => {
    setState({ ...state, variations });
  }, [variations]);
  const history = useHistory();

  const onSelected = (variationKey) => {
    // if already included in the variations array remove it else add it
    if (!state.variations.includes(variationKey)) {
      const v = Array.from(new Set([...state.variations, variationKey]));
      setState({ ...state, variations: v });
    } else if (Array.isArray(state.variations)) {
      const filteredVariations = state.variations.filter(
        (variation) => variation !== variationKey,
      );
      setState({ ...state, variations: filteredVariations });
    }
  };

  const displayPopularVariations = () =>
    state.popularVariations.map((label) => (
      <VariationCard
        variations={variations}
        label={label}
        key={label}
        onSelected={onSelected}
      />
    ));

  return (
    <div className="product-details-container">
      <h2 className="text-gray-600 text-lg font-bold font-round ml-4">
        Select Product Variations
      </h2>

      <input
        placeholder="Select variation"
        className="h-14 border-2 focus:border-zimx-indigo-dark
        focus:outline-none rounded-lg px-4 bg-zimx-indigo-light
        transition-all duration-700 ease-in-out mt-2 font-bold text-zimx-indigo-dark
        "
        value={state.description}
        // onChange={onChangeProductName}
      />
      <h2 className="text-gray-600 text-lg font-bold font-round ml-4 mt-4">
        Popular Variations
      </h2>

      <div className="popular-variations-container">
        {displayPopularVariations()}
      </div>
      <div className="fill" />

      <Button
        onClick={() => {
          onSubmitVariations(state.variations);
          history.push("/shop/products/add/variation-properties");
        }}
        title="Next"
      />

    </div>
  );
};

AddProductVariations.propTypes = {
  onSubmitVariations: PropTypes.func.isRequired,
};

VariationCard.propTypes = {
  label: PropTypes.string.isRequired,
  onSelected: PropTypes.func.isRequired,
};

export default AddProductVariations;
