import React, { useEffect, useState, useRef } from "react";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAuth } from "firebase/auth";
import "../css/addProductScreen.css";
import parse from 'html-react-parser';
import AddProductVariations from "./addProductVariationsScreen";
import { AddVariationProperties } from "./AddVariationDescription";
import { AddPricingComponent } from "./AddPricingComponent";
import { AddQuantityComponent } from "./AddQuantityComponent";
import { AddProductName } from "./AddProductName";
import { AddProductDescription } from "./AddProductDescription";

import { addProduct } from "../../redux/actions/product.actions";
import { Product } from "../../redux/reducers/shop.reducer";

export type ProductScreenState = {
  name: string;
  productDescription: string;
  brand: string;
  barcodeNumber: string;
  variations: string[];
  imagePreviewUrl: string | null | ArrayBuffer;
  displayCrop: boolean;
  crop: Record<string, any>;
  activeKey: string;
  images: Blob[];
  productVariations: Map<string, string[]>;
  pricing: { [key: string]: any }[];
  quantities: { [key: string]: any }[];
  imageUrls?: string[];
  shop?: string;
  products?: { [key: string]: any }[];
};

const AddProductScreen = () => {
  const history = useHistory();
  const isInitialMount = useRef(true);
  const [state, setState] = useState<ProductScreenState>({
    name: "",
    productDescription: "",
    brand: "",
    activeKey: "description",
    variations: [],
    barcodeNumber: "",
    imagePreviewUrl: null,
    displayCrop: false,
    crop: { aspect: 1 },
    images: [],
    productVariations: new Map(),
    pricing: [],
    quantities: [],
  });

  const dispatch = useDispatch();

  // check if user is logged in before proceeding
  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (!user) {
        history.push("/");
      }
    });
  }, [history]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setState({ ...state, displayCrop: true });
    }
  }, [state.imagePreviewUrl]);

  const onSubmitVariations = (variations: string[]) => {
    setState({ ...state, variations });
  };

  const onChangeProductName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const productName = e.target.value;
    setState({ ...state, name: productName });
  };

  const onAddImage = (image: Blob) => {
    const reader = new FileReader();
    if (image) {
      reader.onloadend = () => {
        setState({
          ...state,
          images: [...state.images, image],
          // imagePreviewUrl: reader.result,
        });
      };

      reader.readAsDataURL(image);
      // setState({ ...state, displayCrop: true });
    }
  };

  const onAddProductDescription = (description: {productDescription: string,
    barcodeNumber:string,
    brand: string}) => {
    if (description.productDescription) {
      setState({
        ...state,
        ...description,
      });
    }
  };

  const onAddProductVariations = (productVariations: Map<string, string[]>) => {
    setState({ ...state, productVariations });
  };

  const onAddPricing = (pricing: { [key: string]: any }[]) => {
    setState({ ...state, pricing });
  };

  const onFinish = (products: Product[]) => {
    dispatch(addProduct({ ...state, products }));
  };

  const onAddQuantiites = (quantities: { [key: string]: any }[]) => {
    setState({ ...state, products: quantities });
    onFinish(quantities as Product[]);
  };

  const renderImages = (): JSX.Element[] =>
    state.images.map((image) => {
      const imgSrc = URL.createObjectURL(image);
      return (
        <img
          className="h-28 w-28 rounded-lg shadow-lg overflow-scroll flex-shrink-0 m-2"
          src={imgSrc}
          alt=""
        />
      );
    });
  return (
    <div className="flex-full-container max-w-screen-lg  ">
      <div className="flex sm:flex-col md:flex-row full-width-row">
        <div className="w-full md:col-md-5 flex-full-container">
          <Router>
            <Route exact path="/shop/products/add">
              <AddProductName
                onChangeProductName={onChangeProductName}
                onAddImage={onAddImage}
                value={state.name}
                images={state.images}
              />
            </Route>

            <Route exact path="/shop/products/add/description">
              <AddProductDescription
                onAddProductDescription={onAddProductDescription}
                productDescription={state.productDescription}
                barcodeNumber={state.barcodeNumber}
                brand={state.brand}
              />
            </Route>

            <Route exact path="/shop/products/add/variations">
              <AddProductVariations
                onSubmitVariations={onSubmitVariations}
                variations={state.variations}
              />
            </Route>

            <Route exact path="/shop/products/add/variation-properties">
              <AddVariationProperties
                variations={state.variations}
                onSubmitVariations={onAddProductVariations}
                currentVariations={state.productVariations}
              />
            </Route>

            <Route exact path="/shop/products/add/pricing">
              <AddPricingComponent
                onSubmit={onAddPricing}
                currentPrices={state.pricing}
                variations={state.variations}
                productVariations={state.productVariations}
              />
            </Route>
            <Route exact path="/shop/products/add/quantity">
              <AddQuantityComponent
                // to do change this to state.quantities
                currentPrices={state.pricing}
                variations={state.variations}
                productVariations={state.productVariations}
                onSubmitQuantities={onAddQuantiites}
              />
            </Route>
          </Router>
        </div>

        <div
          className="w-full hidden flex-col flex-wrap md:flex lg:justify-start lg:w-7/12"
          style={{ transform: "scale(0.8)" }}
        >
          <p className="text-2xl text-gray-600 font-rounded font-bold ml-4 mt-12 h-8 w-full">
            Preview
          </p>
          <div className="flex flex-col justify-between lg:flex-row w-full">
            {state.images.length ? (
              <img
                src={URL.createObjectURL(state.images[0])}
                alt="Featured"
                className="h-96 w-96 rounded-xl shadow-xl"
              />
            ) : (
              <div className="h-96 w-96 rounded-xl shad bg-zimx-indigo-100 bg-opacity-50 flex items-center justify-center flex-col">
                <FontAwesomeIcon
                  className="text-4xl"
                  icon={faImage}
                  color="white"
                  size="9x"
                />

                <p className="text-2xl font-rounded text-white">
                  Featured Image
                </p>
              </div>
            )}

            <div className="flex h-32 md:flex-col w-1/3 items-center lg:h-96 overflow-hidden ">
              {renderImages()}
            </div>
          </div>
          {/* Product title goes here */}
          <p className="font-rounded font-bold text-gray-800 text-2xl ml-4 mt-4 opacity-90 w-full">
            {state.name ? state.name : "Product Name"}
          </p>
          <Nav
            activeKey={state.activeKey}
            onSelect={(activeKey) => {
              setState({ ...state, activeKey: activeKey as string });
            }}
            className="w-full"
          >
            <Nav.Item>
              <Nav.Link eventKey="description" className="product-nav-link">
                Description
              </Nav.Link>
            </Nav.Item>
          </Nav>

          {state.activeKey === "description" && <div className="mt-4">{parse(state.productDescription)}</div> }
        </div>
      </div>
    </div>
  );
};

export default AddProductScreen;
