import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Button } from "../../common/button/button";
import { ZInput } from "../../common/input/ZInput";
import { ScreenContainer } from "../../common/screenContainer";
import { Country, CountryModalProps } from "./Countries.types";
import { useCountries } from "./useCountries";

const CountryDetailsModal: React.FC<CountryModalProps> = ({
  isOpen,
  toggleModal,
  country,
}) => {
  const [state, setState] = useState<Country | undefined>(country);

  useEffect(() => {
    setState(country);
  }, [country]);

  const { onUpdateCountry, onAddCountry } = useCountries();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name && value) {
      setState({ ...state, [name]: value } as Country);
    }
  };

  return (
    <Modal
      className="w-full md:w-1/3 bg-white rounded-lg overflow-hidden focus:outline-none z-50 p-4 max-w-screen-xl"
      onRequestClose={() => toggleModal()}
      shouldCloseOnEsc
      overlayClassName="bg-black bg-opacity-20 w-screen h-screen absolute top-0 left-0 items-center justify-center flex overflow-hidden"
      isOpen={isOpen}
      appElement={document?.querySelector("#root") as HTMLElement}
    >
      <h1 className="font-bold text-3xl text-gray-700 mb-4">
        {country ? "Edit country" : "Add country"}
      </h1>

      <div className="mb-4">
        <ZInput
          placeholder="Country name"
          name="name"
          value={state?.name}
          onChange={onChange}
        />
      </div>
      <div className="mb-4">
        <ZInput
          placeholder="Country code"
          name="countryCode"
          value={state?.countryCode}
          onChange={onChange}
        />
      </div>
      <div className="mb-4">
        <ZInput
          placeholder="Country dial code"
          name="dialCode"
          type="number"
          value={state?.dialCode ? state?.dialCode.toString() : ""}
          onChange={onChange}
        />
      </div>
      <div className="mb-4">
        <ZInput
          placeholder="Country latitude"
          name="latitude"
          type="number"
          value={state?.latitude ? state?.latitude.toString() : ""}
          onChange={onChange}
        />
      </div>
      <div className="mb-4">
        <ZInput
          placeholder="Country longitude"
          name="longitude"
          type="number"
          value={state?.longitude ? state?.longitude.toString() : ""}
          onChange={onChange}
        />
      </div>

      <Button
        title="Save"
        onClick={() => {
          if (state?.id) {
            onUpdateCountry(state);
          } else {
            onAddCountry(state!);
          }
          toggleModal();
        }}
      />
    </Modal>
  );
};

export const Countries: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>();
  const toggleModal = (item: Country | undefined) => {
    setSelectedCountry(item);
    setIsModalOpen((prevState) => !prevState);
  };

  const { countries, loading, error, onDeleteCountry } = useCountries();

  const renderCountries = () =>
    countries.map(
      (country) =>
        country && (
          <tr className="hover:bg-gray-100">
            <td>{country.name}</td>
            <td>{country.countryCode}</td>
            <td>{country.dialCode}</td>
            <td className="hidden md:table-cell">{country.latitude}</td>
            <td className="hidden md:table-cell">{country.longitude}</td>
            <td>
              <button
                type="button"
                onClick={() => {
                  toggleModal(country);
                }}
              >
                <FontAwesomeIcon
                  className="text-zimx-indigo-dark text-xl"
                  icon={faEdit}
                />
              </button>
            </td>
            <td>
              <button
                type="button"
                onClick={() => onDeleteCountry(country.id!)}
              >
                <FontAwesomeIcon
                  className="text-red-500 text-xl"
                  icon={faTrash}
                />
              </button>
            </td>
          </tr>
        ),
    );

  return (
    <ScreenContainer title="Countries">
      <table className="table table-auto mt-8 mb-8 w-full">
        <thead className="text-left">
          <th>Country name</th>
          <th>Country code</th>
          <th>Dial Code</th>
          <th className="hidden md:table-cell">Latitude</th>
          <th className="hidden md:table-cell">Longitude</th>
          <th>{}</th>
          <th>{}</th>
        </thead>
        <tbody>{countries && !loading && !error && renderCountries()}</tbody>
      </table>
      <Button title="Add Country" onClick={() => toggleModal(undefined)} />
      <CountryDetailsModal
        isOpen={isModalOpen}
        toggleModal={() => toggleModal(undefined)}
        country={selectedCountry}
      />
    </ScreenContainer>

  );
};
