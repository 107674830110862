/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Product } from "../../redux/reducers/shop.reducer";

import { Button } from "../common/button/button";
import { ScreenContainer } from "../common/screenContainer";
import { CartItem } from "./CartItems";
import { useCart } from "./useCart";

export const Cart = () => {
  const [total, setTotal] = useState<number>();
  const { loading, error, cart, onUpdateQuantity, onDeleteCartItem } = useCart();

  useEffect(() => {
    if (Array.isArray(cart) && !loading && !error) {
      const temp = cart?.reduce((_acc, item) => {
        if (item) {
          const cartProduct: Product = item.productGroup.products.find(
            (p) => p._id === item.product,
          )!;
          return Number(cartProduct?.price) * Number(item?.quantity);
        }

        return 0;
      }, 0);

      setTotal(temp);
    }
  }, [cart]);

  const history = useHistory();
  const navigateToAddressScreen = () => {
    history.push({ pathname: "/add-address" });
  };

  return (
    <ScreenContainer
      loading={loading}
      title="Shopping Cart"
    >
      <div className="w-full mt-4">

        {cart?.map?.((item) => (
          <CartItem
            item={item}
            onRemoveCartItem={onDeleteCartItem}
            onIncreaseQuantity={onUpdateQuantity}
            onDecreaseQuantity={onUpdateQuantity}
          />
        ))}

        <div className="mt-4 w-full flex sm:justify-between md:justify-end float-right">
          <div className="flex flex-col w-full">
            <div className="text-2xl font-bold text-grey-700 flex justify-between w-1/3 pr-4">
              <label className="mb-0 flex-row"> Total - ${total}</label>
            </div>

            <Button
              onClick={() => navigateToAddressScreen()}
              title="Check Out"
            />

          </div>
        </div>
      </div>
    </ScreenContainer>
  );
};
