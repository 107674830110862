import { useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useDispatch } from "react-redux";
import {
  fetchFactory,
  RequestMethods,
} from "../../../redux/actions/fetchFactory";
import { getUserShop } from "../../../redux/actions/shop.actions";
import { IShop } from "../../../redux/reducers/shop.reducer";

export const useShop = (
  shopId?: string,
): {
  shop?: IShop;
  loading: boolean;
  error: boolean;
  onDeleteShop: (shopId: string) => void;
  // eslint-disable-next-line function-paren-newline
  onUpdateShop: (shop: IShop) => void;
} => {
  const dispatch = useDispatch();
  const { data, isLoading, isError, isLoadingError } = useQuery<IShop>(
    `shop ${shopId}`,
    async () =>
      fetchFactory({
        endpoint: `/api/shop`,
        method: RequestMethods.GET,
      }),
  );

  const deleteShop = useMutation(
    "shop",
    async () =>
      fetchFactory({
        endpoint: `/api/shop/${shopId}`,
        method: RequestMethods.DELETE,
        successMessage: "Deleted country successfully",
        errorMessage: "Failed to delete country",
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const updateShop = useMutation(
    "shop",
    async (shop: IShop) =>
      fetchFactory({
        endpoint: `/api/shop/${shop.id}`,
        method: RequestMethods.PUT,
        successMessage: "Deleted city successfully",
        errorMessage: "Failed to delete city",
        body: shop,
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const onDeleteShop = () => deleteShop.mutate();

  const onUpdateShop = (shop: IShop) => {
    updateShop.mutate(shop);
  };

  useEffect(() => {
    if (data) {
      dispatch(getUserShop(data));
    }
  }, [data]);

  return {
    shop: data,
    loading: isLoading,
    error: isError || isLoadingError,
    onDeleteShop,
    onUpdateShop,
  };
};
