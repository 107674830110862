import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLocation } from "react-router-dom";

interface ButtonProps {
  phoneNumber: string;
  disabled?: boolean
}

export const WhatsappButton: React.FC<ButtonProps> = ({ phoneNumber }) => (
  <a
    type="button"
    className={` h-12 items-center justify-center  w-full rounded-md font-extrabold text-white bg flex flex-row bg-whatsapp`}
    href={`whatsapp://send?phone=${phoneNumber}&text=${window.location.href}%20Hello, I would like to know more about this product`}
  >
    <>
      <FontAwesomeIcon size="2x" icon={faWhatsapp} color="white" />
      <div className="ml-4">Message on Whatsapp</div>
    </>

  </a>
);
