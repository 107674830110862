import React from "react";
import { animated, useSpring } from "react-spring";

export const ZLoader = () => {
  const animations1 = useSpring({
    loop: true,
    to: [
      {
        top: 10,
        left: 43,
        width: 32,
        height: 32,
        backgroundColor: "rgb(99, 102, 241)",
      },
      {
        top: 60,
        left: 10,
        width: 20,
        height: 20,
        backgroundColor: "rgb(245, 158, 11)",
      },
      {
        top: 0,
        left: 5,
        width: 20,
        height: 20,
        backgroundColor: "rgb(16, 185, 129)",
      },
    ],
    from: {
      top: 0,
      left: 5,
      width: 20,
      height: 20,
      backgroundColor: "rgb(16, 185, 129)",
    },
  });

  const animations2 = useSpring({
    loop: true,
    to: [
      {
        top: 60,
        left: 10,
        width: 20,
        height: 20,
        backgroundColor: "rgb(245, 158, 11)",
      },
      {
        top: 0,
        left: 5,
        width: 20,
        height: 20,
        backgroundColor: "rgb(16, 185, 129)",
      },
      {
        top: 10,
        left: 43,
        width: 32,
        height: 32,
        backgroundColor: "rgb(99, 102, 241)",
      },
    ],
    from: {
      top: 10,
      left: 43,
      width: 32,
      height: 32,
      backgroundColor: "rgb(99, 102, 241)",
    },
  });

  const animations3 = useSpring({
    loop: true,
    from: {
      top: 20,
      left: 10,
      width: 20,
      height: 20,
      backgroundColor: "rgb(245, 158, 11)",
    },
    to: [
      {
        top: -40,
        left: 5,
        width: 20,
        height: 20,
        backgroundColor: "rgb(16, 185, 129)",
      },
      {
        top: -30,
        left: 43,
        width: 32,
        height: 32,
        backgroundColor: "rgb(99, 102, 241)",
      },
      {
        top: 20,
        left: 10,
        width: 20,
        height: 20,
        backgroundColor: "rgb(245, 158, 11)",
      },
    ],
  });

  return (
    <div>
      <div className="w-20 h-20  relative flex ">
        <animated.div
          className="rounded-full absolute"
          style={animations1 as any}
        />
        <animated.div
          className=" h-8 w-8 rounded-full absolute"
          style={animations2 as any}
        />
        <animated.div
          className="bg-yellow-500 h-5 w-5 mt-10 bottom-0 left-6 rounded-full absolute"
          style={animations3 as any}
        />
      </div>
    </div>
  );
};
