/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ProductGroup } from "../../redux/reducers/shop.reducer";
import { QuantityButton } from "../common/quantityButton/quantityButton";

interface ICartItem {
  quantity: number;
  productGroup: ProductGroup;
  _id: string;
  product: string;
}

interface CartItemProps {
  item: ICartItem;
  onIncreaseQuantity: ({ quantity, _id }: Partial<ICartItem>) => void;
  onDecreaseQuantity: ({ quantity, _id }: Partial<ICartItem>) => void;
  onRemoveCartItem: (id: string) => void;
}

export const CartItem: React.FC<CartItemProps> = ({
  item,
  onIncreaseQuantity,
  onDecreaseQuantity,
  onRemoveCartItem,
}) => {
  const cartProduct = item.productGroup.products.find(
    (p) => p._id === item.product,
  );

  return (
    <div key={item._id} className="w-full flex items-start border-b py-4">
      <img
        src={item.productGroup.imageUrls[0]}
        alt=""
        className="w-1/3 md:w-1/5 rounded-md overflow-hidden"
        style={{ aspectRatio: "1" }}
        draggable={false}
      />
      <div className="flex flex-col w-full h-full flex-1 pl-4 md:pr-0">
        {/* Render name and price */}
        <div className="w-full flex">
          <div className="w-full flex items-center font-bold text-gray-700">
            <p className="w-full py-2 pr-4 flex items-center justify-between m-0 text-xl ">
              <p className="m-0 flex">
                {item.productGroup.name}

                <p className="ml-4 m-0 text-green-700 flex font-bold">
                  ${cartProduct?.price}
                </p>
              </p>

              <div className="w-1/3 hidden md:flex">
                <QuantityButton
                  quantity={item.quantity}
                  _id={item._id}
                  onDecreaseQuantity={onDecreaseQuantity}
                  onIncreaseQuantity={onIncreaseQuantity}
                  label="Qty"
                />
              </div>
            </p>
            <button
              type="button"
              className="flex mx-1 h-12 w-12 rounded-full border-2 border-red-400 items-center justify-center"
              style={{ aspectRatio: "1" }}
              onClick={() => onRemoveCartItem(item._id)}
            >
              <FontAwesomeIcon
                className="text-red-500 text-xl"
                icon={faTrash}
              />
            </button>
          </div>
        </div>

        {/* Render variations */}
        <div className="grid-flow-row grid-cols-3 gap-2 md:grid-cols-4 mt-4 hidden md:grid">
          {cartProduct &&
            Object.keys(cartProduct).map((productKey) => {
              const fixedCartProperties = [
                "_id",
                "createdAt",
                "price",
                "quantity",
                "updatedAt",
              ];

              if (!fixedCartProperties.includes(productKey)) {
                return (
                  <div key={productKey}>
                    <button
                      type="button"
                      className={`w-full transition-all duration-300 rounded-md focus:outline-none text-center
             hover:shadow-md cursor-pointer  
             shadow-md py-2 px-1 overflow-ellipsis relative overflow-hidden 
             hover:opacity-80 bg-zimx-indigo-100 text-white`}
                    >
                      <div className="absolute w-3 h-3 opacity-30 bg-green-400 rotate-45 rounded-sm top-2 left-1" />
                      <div className="absolute w-5 h-5 opacity-30 bg-indigo-400 rounded-full top-5 left-2" />
                      <div className="absolute w-14 h-14 opacity-30 bg-blue-500 rounded-full -right-7 -bottom-7" />
                      {(cartProduct as Record<string, string>)[productKey]}
                    </button>
                  </div>
                );
              }

              return null;
            })
          // eslint-disable-next-line react/jsx-curly-newline
          }
        </div>

        {/* Render quantities - add option to update quantites */}
        <div className="md:hidden flex mt-4">
          <QuantityButton
            quantity={item.quantity}
            _id={item._id}
            onDecreaseQuantity={onDecreaseQuantity}
            onIncreaseQuantity={onIncreaseQuantity}
            label="Qty"
          />
        </div>
      </div>
    </div>
  );
};
