import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Select, { SingleValue } from "react-select";
import { Button } from "../../common/button/button";
import { ZInput } from "../../common/input/ZInput";
import { ScreenContainer } from "../../common/screenContainer";
import { Country } from "../countries/Countries.types";
import { useCountries } from "../countries/useCountries";
import { City, CityModalProps } from "./Cities.types";
import { useCities } from "./useCities";

const CityDetailsModal: React.FC<CityModalProps> = ({
  isOpen,
  toggleModal,
  city,
}) => {
  const [state, setState] = useState<City | undefined>(city);
  const { countries } = useCountries();

  useEffect(() => {
    setState(city);
  }, [city]);

  const { onUpdateCity, onAddCity } = useCities();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name && value) {
      setState({ ...state, [name]: value } as City);
    }
  };

  const onSelectCountry = (
    option: SingleValue<{ label: string; value: string | undefined }>,
  ) => {
    setState((prevState) => ({ ...prevState, country: option?.value } as City));
  };

  const getCountry = (): SingleValue<{
    label: string;
    value: string | undefined;
  }> => {
    const country = countries.find(
      (c) => c.id === (state?.country as City)?.id || c.id === state?.country,
    );

    return { value: country?.id, label: country?.name as string };
  };

  return (
    <Modal
      className="w-full md:w-1/3 bg-white rounded-lg overflow-hidden focus:outline-none z-50 p-4 max-w-screen-xl"
      onRequestClose={() => toggleModal()}
      shouldCloseOnEsc
      overlayClassName="bg-black bg-opacity-20 w-screen h-screen absolute top-0 left-0 items-center justify-center flex overflow-hidden"
      isOpen={isOpen}
      appElement={document?.querySelector("#root") as HTMLElement}
    >
      <h1 className="font-bold text-3xl text-gray-700 mb-4">
        {city ? "Edit city" : "Add city"}
      </h1>

      <div className="mb-4">
        <Select
          options={countries.map((country) => ({
            label: country.name,
            value: country.id,
          }))}
          value={getCountry()}
          onChange={(
            option: SingleValue<{ label: string; value: string | undefined }>,
          ) => onSelectCountry(option)}
        />
      </div>

      <div className="mb-4">
        <ZInput
          placeholder="City name"
          name="name"
          value={state?.name}
          onChange={onChange}
        />
      </div>

      <div className="mb-4">
        <ZInput
          placeholder="City latitude"
          name="latitude"
          type="number"
          value={state?.latitude ? state?.latitude.toString() : ""}
          onChange={onChange}
        />
      </div>
      <div className="mb-4">
        <ZInput
          placeholder="City longitude"
          name="longitude"
          type="number"
          value={state?.longitude ? state?.longitude.toString() : ""}
          onChange={onChange}
        />
      </div>

      <Button
        title="Save"
        onClick={() => {
          if (state?.id) {
            onUpdateCity(state);
          } else {
            onAddCity(state!);
          }
          toggleModal();
        }}
      />
    </Modal>
  );
};

export const Cities: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedCity, setSelectedCity] = useState<City | undefined>();
  const toggleModal = (item: City | undefined) => {
    setSelectedCity(item);
    setIsModalOpen((prevState) => !prevState);
  };

  const { cities, loading, error, onDeleteCity } = useCities();

  const renderCountries = () =>
    cities.map(
      (city) =>
        city && (
          <tr className="hover:bg-gray-100">
            <td>{city.name}</td>
            <td>{(city?.country as Country)?.name}</td>
            <td className="hidden md:table-cell">{city.latitude}</td>
            <td className="hidden md:table-cell">{city.longitude}</td>
            <td>
              <button
                type="button"
                onClick={() => {
                  toggleModal(city);
                }}
              >
                <FontAwesomeIcon
                  className="text-zimx-indigo-dark text-xl"
                  icon={faEdit}
                />
              </button>
            </td>
            <td>
              <button type="button" onClick={() => onDeleteCity(city.id!)}>
                <FontAwesomeIcon
                  className="text-red-500 text-xl"
                  icon={faTrash}
                />
              </button>
            </td>
          </tr>
        ),
    );

  return (
    <ScreenContainer title="Cities">
      <table className="table table-auto max-w-screen-xl mt-8 mb-8 w-full">
        <thead className="text-left">
          <th>City name</th>
          <th>Country</th>
          <th className="hidden md:table-cell">Latitude</th>
          <th className="hidden md:table-cell">Longitude</th>
          <th>{}</th>
          <th>{}</th>
        </thead>
        <tbody>{cities && !loading && !error && renderCountries()}</tbody>
      </table>
      <Button title="Add City" onClick={() => toggleModal(undefined)} />
      <CityDetailsModal
        isOpen={isModalOpen}
        toggleModal={() => toggleModal(undefined)}
        city={selectedCity}
      />
    </ScreenContainer>
  );
};
