/* eslint-disable indent */
import { ActionInterface } from "../action.types";
import * as actionTypes from "../action.types";
import { Address } from "../../screens/Address";

export type Product = {
  price: number | string;
  quantity: number | string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type ProductGroup = {
  shop: string;
  name: string;
  productDescription: string;
  products: Product[];
  imageUrls: string[];
  variations: string[];
  shopDetails?: IShop;
  createdAt?: Date;
  _id: string;
  brand?: {
    name: string;
    logo: string;
  };
};

export type IShop = {
  name: string;
  logo: string;
  verified?: boolean;
  address: Address;
  products?: ProductGroup[];
  createdAt?: string;
  id?: string;
};

const initialState: IShop = {
  name: "",
  logo: "",
  verified: false,
  address: {} as Address,
  products: [],
  id: "",
};

export const ShopReducer = (
  state = initialState,
  action: ActionInterface,
): IShop => {
  switch (action.type) {
    case actionTypes.API_GET_USER_SHOP:
      return { ...state, ...action.payload };
    case actionTypes.API_GET_PRODUCTS:
      return { ...state, products: action.payload };
    default:
      return initialState;
  }
};
