import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  fetchFactory,
  RequestMethods,
} from "../../../redux/actions/fetchFactory";
import { RootState } from "../../../redux/store";
import { Brand } from "./Brands.types";

export const useBrands = (): {
  brands: Brand[];
  loading: boolean;
  error: boolean;
  onDeleteBrand: (brandId: string) => void;
  onUpdateBrand: (brand: Brand) => void;
  // eslint-disable-next-line function-paren-newline
  onAddBrand: (brand: Brand) => void;
} => {
  const reduxAccountState = useSelector((state: RootState) => state.user);
  const queryClient = useQueryClient();
  const { data = [], isLoading, isError, isLoadingError } = useQuery<Brand[]>(
    "brands",
    async () =>
      fetchFactory({
        endpoint: `/api/brands`,
        method: RequestMethods.GET,
        token: reduxAccountState.token,
      }),
    {
      refetchInterval: false,
    },
  );

  const deleteBrand = useMutation(
    "brands",
    async (brandId: string) =>
      fetchFactory({
        endpoint: `/api/brands/${brandId}`,
        method: RequestMethods.DELETE,
        successMessage: "Deleted brand successfully",
        errorMessage: "Failed to delete brand",
        token: reduxAccountState.token,
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const addBrand = useMutation(
    async (brand: Brand) =>
      fetchFactory({
        endpoint: `/api/brands`,
        method: RequestMethods.POST,
        body: brand,
        successMessage: "Added brand succesfully",
        token: reduxAccountState.token,
        errorMessage: "Failed to add brand",
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const updateBrand = useMutation(
    async (brand: Brand) =>
      fetchFactory({
        endpoint: `/api/brands/${brand.id}`,
        method: RequestMethods.PUT,
        successMessage: "Updated brand successfully",
        errorMessage: "Failed to update brand",
        token: reduxAccountState.token,
        body: brand,
      }),
    {
      onSuccess: (response) => {
        queryClient.setQueryData("brands", response);
      },
    },
    // eslint-disable-next-line function-paren-newline
  );

  const onDeleteBrand = (brandId: string) =>
    deleteBrand.mutate(brandId, {
      onSuccess: (response) => {
        queryClient.setQueryData("brands", response);
      },
    });

  const onUpdateBrand = (brand: Brand) => {
    updateBrand.mutate(brand);
  };

  const onAddBrand = (brand: Brand) => {
    addBrand.mutate(brand);
  };

  return {
    brands: data,
    loading: isLoading,
    error: isError || isLoadingError,
    onAddBrand,
    onDeleteBrand,
    onUpdateBrand,
  };
};
