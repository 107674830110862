/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { objectsAreEqual } from "./objectCompare";
import { Button } from "../common/button/button";

const PricingRow: React.FC<{
  variationObject: { [key: string]: string };
  index: number;
  addVariationQuantity(
    dependency: { [key: string]: string },
    price: number,
  ): void;
}> = ({ variationObject, index, addVariationQuantity }) => {
  const onChangeQuantity = (quantity: string) => {
    const quantityNum: number = Number(quantity);
    addVariationQuantity(variationObject, quantityNum);
  };

  return (
    <div
      style={{
        backgroundColor: index % 2 === 0 ? "#f1ebfd" : "transparent",
        display: "grid",
        gridTemplateColumns: `repeat( ${Object.keys(variationObject).filter(
          (key) => key !== "quantity",
        ).length + 1}, 1fr)`,
        placeItems: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      {Object.keys(variationObject)
        .filter((key) => key !== "quantity")
        .map(
          (value) =>
            index === 0 && <p style={{ fontWeight: "bold" }}>{value}</p>,
        )}

      {index === 0 && <p style={{ fontWeight: "bold" }}>Quantity</p>}
      {Object.keys(variationObject)
        .filter((key) => key !== "quantity")
        .map((value: string) => (
          <div>
            <p className="pricing-text">{variationObject[value]}</p>
          </div>
        ))}
      <input
        type="number"
        className="w-20 h-10 text-right bg-white border-2 focus:border-zimx-indigo-dark rounded-md focus:outline-none"
        value={variationObject.quantity}
        onChange={(e) => onChangeQuantity(e.target.value)}
      />
    </div>
  );
};

export const AddQuantityComponent: React.FC<{
  currentPrices: { [key: string]: any }[];
  variations: string[];
  productVariations: Map<string, string[]>;
  onSubmitQuantities: (quantities: { [key: string]: any }[]) => void;
}> = ({ currentPrices, onSubmitQuantities }) => {
  const [quantityDependency, setQuantityDependency] = useState<
    { [key: string]: any }[]
  >([]);

  useEffect(() => {
    setQuantityDependency(currentPrices);
  }, [currentPrices]);

  const onSubmit = () => {
    let updatedProducts: { [key: string]: string }[] = [];
    quantityDependency.map((product: { [key: string]: string }): void => {
      quantityDependency.map((dependency) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { quantity, ...dependantFields } = dependency;
        const shouldUpdate = objectsAreEqual(product, dependantFields);
        if (shouldUpdate) {
          updatedProducts = [...updatedProducts, { ...product, ...dependency }];
        }
      });
    });

    onSubmitQuantities(updatedProducts);
  };

  const addVariationQuantity = (
    dependency: { [key: string]: string },
    quantity: number,
  ): void => {
    const updatedQuantityDependency = quantityDependency.map(
      (productDep: { [key: string]: string }) => {
        const shouldUpdate = objectsAreEqual(productDep, dependency);
        if (typeof quantity === "number" && shouldUpdate) {
          return { ...productDep, quantity };
        }
        return productDep;
      },
    );

    setQuantityDependency(updatedQuantityDependency);
  };

  return (
    <div className="product-details-container">
      <h2 className="text-gray-600 text-lg font-bold font-round ml-4 mb-4">
        Enter product quantities
      </h2>

      <div style={{ marginTop: 20 }}>
        {quantityDependency.map(
          (dependency: { [key: string]: string }, index: number) => (
            <PricingRow
              variationObject={dependency}
              index={index}
              addVariationQuantity={addVariationQuantity}
            />
          ),
        )}
      </div>
      <div className="fill" />

      <Button
        title="Save Product"
        onClick={onSubmit}
      />
    </div>
  );
};
