import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Select, { SingleValue } from "react-select";
import { Button } from "../../common/button/button";
import { ZInput } from "../../common/input/ZInput";
import { ScreenContainer } from "../../common/screenContainer";
import { City } from "../cities/Cities.types";
import { useCities } from "../cities/useCities";
import { Country } from "../countries/Countries.types";
import { useCountries } from "../countries/useCountries";
import { Town, TownModalProps } from "./Towns.types";
import { useTowns } from "./useTowns";

const TownDetailsModal: React.FC<TownModalProps> = ({
  isOpen,
  toggleModal,
  town,
}) => {
  const [state, setState] = useState<Town | undefined>(town);
  const { countries } = useCountries();
  const { cities, loading: citiesLoading } = useCities(
    (state?.country as Country)?.id || (state?.country as string),
  );

  useEffect(() => {
    setState(town);
  }, [town]);

  const { onUpdateTown, onAddTown } = useTowns();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name && value) {
      setState({ ...state, [name]: value } as Town);
    }
  };

  const onSelectCountry = (
    option: SingleValue<{ label: string; value: string | undefined }>,
  ) => {
    setState((prevState) => ({ ...prevState, country: option?.value } as Town));
  };

  const onSelectCity = (
    option: SingleValue<{ label: string; value: string | undefined }>,
  ) => {
    setState((prevState) => ({ ...prevState, city: option?.value } as Town));
  };

  const getCity = (): SingleValue<{
    label: string;
    value: string | undefined;
  }> => {
    const city = cities.find(
      (c) => c.id === (state?.city as City)?.id || c.id === state?.city,
    );

    return { value: city?.id, label: city?.name as string };
  };

  const getCountry = (): SingleValue<{
    label: string;
    value: string | undefined;
  }> => {
    const country = countries.find(
      (c) => c.id === (state?.country as City)?.id || c.id === state?.country,
    );

    return { value: country?.id, label: country?.name as string };
  };

  return (
    <Modal
      className="w-full md:w-1/3 bg-white rounded-lg overflow-hidden focus:outline-none z-50 p-4 max-w-screen-xl"
      onRequestClose={() => toggleModal()}
      shouldCloseOnEsc
      overlayClassName="bg-black bg-opacity-20 w-screen h-screen absolute top-0 left-0 items-center justify-center flex overflow-hidden"
      isOpen={isOpen}
      appElement={document?.querySelector("#root") as HTMLElement}
    >
      <h1 className="font-bold text-3xl text-gray-700 mb-4">
        {town ? "Edit town" : "Add town"}
      </h1>

      <div className="mb-4">
        <Select
          options={countries.map((country) => ({
            label: country.name,
            value: country.id,
          }))}
          value={getCountry()}
          onChange={(
            option: SingleValue<{ label: string; value: string | undefined }>,
          ) => onSelectCountry(option)}
        />
      </div>

      <div className="mb-4">
        <Select
          options={cities.map((city) => ({
            label: city.name,
            value: city.id,
          }))}
          isDisabled={!state?.country || citiesLoading}
          value={getCity()}
          onChange={(
            option: SingleValue<{ label: string; value: string | undefined }>,
          ) => onSelectCity(option)}
        />
      </div>

      <div className="mb-4">
        <ZInput
          placeholder="Town name"
          name="name"
          value={state?.name}
          onChange={onChange}
        />
      </div>

      <div className="mb-4">
        <ZInput
          placeholder="Town latitude"
          name="latitude"
          type="number"
          value={state?.latitude ? state?.latitude.toString() : ""}
          onChange={onChange}
        />
      </div>
      <div className="mb-4">
        <ZInput
          placeholder="Town longitude"
          name="longitude"
          type="number"
          value={state?.longitude ? state?.longitude.toString() : ""}
          onChange={onChange}
        />
      </div>

      <Button
        title="Save"
        onClick={() => {
          if (state?.id) {
            onUpdateTown(state);
          } else {
            onAddTown(state!);
          }
          toggleModal();
        }}
      />
    </Modal>
  );
};

export const Towns: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTown, setSelectedTown] = useState<Town | undefined>();
  const toggleModal = (item: City | undefined) => {
    setSelectedTown(item);
    setIsModalOpen((prevState) => !prevState);
  };

  const { towns, loading, error, onDeleteTown } = useTowns();

  const renderTowns = () =>
    towns.map(
      (town) =>
        town && (
          <tr className="hover:bg-gray-100">
            <td>{town.name}</td>
            <td>{(town?.city as City)?.name}</td>
            <td>{(town?.country as Country)?.name}</td>
            <td className="hidden md:table-cell">{town.latitude}</td>
            <td className="hidden md:table-cell">{town.longitude}</td>
            <td>
              <button
                type="button"
                onClick={() => {
                  toggleModal(town);
                }}
              >
                <FontAwesomeIcon
                  className="text-zimx-indigo-dark text-xl"
                  icon={faEdit}
                />
              </button>
            </td>
            <td>
              <button type="button" onClick={() => onDeleteTown(town.id!)}>
                <FontAwesomeIcon
                  className="text-red-500 text-xl"
                  icon={faTrash}
                />
              </button>
            </td>
          </tr>
        ),
    );

  return (
    <ScreenContainer title="Towns">
      <table className="table-auto mt-8 mb-8 w-full">
        <thead className="text-left">
          <th>Town name</th>
          <th>City</th>
          <th>Country</th>
          <th className="hidden md:table-cell">Latitude</th>
          <th className="hidden md:table-cell">Longitude</th>
          <th>{}</th>
          <th>{}</th>
        </thead>
        <tbody>{towns && !loading && !error && renderTowns()}</tbody>
      </table>
      <Button title="Add Town" onClick={() => toggleModal(undefined)} />
      <TownDetailsModal
        isOpen={isModalOpen}
        toggleModal={() => toggleModal(undefined)}
        town={selectedTown}
      />
    </ScreenContainer>
  );
};
