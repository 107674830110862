import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import {
  fetchFactory,
  RequestMethods,
} from "../../../redux/actions/fetchFactory";
import { RootState } from "../../../redux/store";
import { Country } from "./Countries.types";

export const useCountries = (): {
  countries: Country[];
  loading: boolean;
  error: boolean;
  onDeleteCountry: (countryId: string) => void;
  onUpdateCountry: (country: Country) => void;
  onAddCountry: (country: Country) => void;
} => {
  const reduxAccountState = useSelector((state: RootState) => state.user);

  const { data = [], isLoading, isError, isLoadingError } = useQuery<Country[]>(
    "countries",
    async () =>
      fetchFactory({
        endpoint: `/api/countries`,
        method: RequestMethods.GET,
      }),
  );

  const deleteCountry = useMutation(
    "countries",
    async (countryId: string) =>
      fetchFactory({
        endpoint: `/api/countries/${countryId}`,
        method: RequestMethods.DELETE,
        successMessage: "Deleted country successfully",
        errorMessage: "Failed to delete country",
        token: reduxAccountState.token,
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const addCountry = useMutation(
    async (country: Country) =>
      fetchFactory({
        endpoint: `/api/countries`,
        method: RequestMethods.POST,
        body: country,
        successMessage: "Added country succesfully",
        token: reduxAccountState.token,
        errorMessage: "Failed to add country",
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const updateCountry = useMutation(
    "countries",
    async (country: Country) =>
      fetchFactory({
        endpoint: `/api/countries/${country.id}`,
        method: RequestMethods.PUT,
        successMessage: "Deleted country successfully",
        errorMessage: "Failed to delete country",
        token: reduxAccountState.token,
        body: country,
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const onDeleteCountry = (countryId: string) =>
    deleteCountry.mutate(countryId);

  const onUpdateCountry = (country: Country) => {
    updateCountry.mutate(country);
  };

  const onAddCountry = (country: Country) => {
    addCountry.mutate(country);
  };

  return {
    countries: data,
    loading: isLoading,
    error: isError || isLoadingError,
    onAddCountry,
    onDeleteCountry,
    onUpdateCountry,
  };
};
