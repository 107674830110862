import { faArrowRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../common/button/button";
import { ImageCroppingModal } from "../user/createShop";

export const AddProductName = ({
  value,
  onChangeProductName,
  images,
  onAddImage,
}: {
  value: string;
  onChangeProductName: React.ChangeEventHandler<HTMLInputElement>;
  onAddImage: any;
  images: Blob[];
}) => {
  const history = useHistory();
  const goToAddDescription = () => {
    history.push("/shop/products/add/description");
  };

  const [image, setImage] = useState<string>("");
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [productImages, setProductImages] = useState<Blob[]>([]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (image?.trim() !== "") {
      setIsOpen(true);
    }
  }, [image]);

  useEffect(() => {
    setProductImages(images);
  }, [images]);

  useEffect(() => {
    onAddImage(imageBlob);
  }, [imageBlob]);

  const onSelectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
    }
  };

  const toggleModal = () => {
    setIsOpen((prevState) => !prevState);
    setImage("");
  };

  const onCropImage = (croppedImageFile: Blob) => {
    if (croppedImageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(croppedImageFile);
      reader.onloadend = () => {
        setImageBlob(croppedImageFile);
      };
    }
    toggleModal();
  };

  return (
    <div className="product-details-container">
      <h2 className="text-gray-600 text-lg font-bold font-round ml-4">
        Enter Product Name
      </h2>

      <input
        placeholder="Product Name"
        className="h-14 border-2 focus:border-zimx-indigo-dark
        focus:outline-none rounded-lg px-4 bg-zimx-indigo-light
        transition-all duration-700 ease-in-out mt-2 font-bold text-zimx-indigo-dark
        "
        value={value}
        onChange={onChangeProductName}
      />

      <h2 className="text-gray-600 text-lg font-bold font-round ml-4 mt-4">
        Select product images
      </h2>
      <label
        className="flex overflow-hidden rounded-lg justify-between mt-2 shadow-lg"
        htmlFor="other-images"
      >
        <input
          type="file"
          className="hidden"
          id="other-images"
          onChange={onSelectImage}
          accept="image/*"
        />
        <div className="bg-indigo-300 rounded-l-lg w-1/3 items-center flex justify-center ">
          <FontAwesomeIcon icon={faPlus} color="white" size="lg" />
        </div>

        <div className="w-2/3 pl-8 bg-indigo-100 h-20 border-r-8 rounded-r-lg border-indigo-300 font-rounded font-bold text-lg text-zimx-indigo-dark flex items-center">
          <p className="text-left mb-0">Add Product Images</p>
        </div>
      </label>
      <div className="fill" />

      <Button
        onClick={goToAddDescription}
        title="Next"
      />

      <ImageCroppingModal
        image={image}
        isOpen={isOpen}
        onCrop={onCropImage}
        toggleModal={toggleModal}
      />
    </div>
  );
};
