import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";

export enum RequestMethods {
  POST = "POST",
  GET = "GET",
  DELETE = "DELETE",
  PUT = "PUT",
}

interface FetchInterFace {
  endpoint: string;
  body?: any;
  token?: string | null;
  successMessage?: string;
  errorMessage?: string;
  method: RequestMethods;
}

export const fetchFactory = async ({
  endpoint,
  body,
  errorMessage,
  successMessage,
  method,
}: FetchInterFace): Promise<any> => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();
    const response = await fetch(`${endpoint}`, {
      body: JSON.stringify(body),
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (response.status >= 200 && response.status < 300) {
      const responseJSON = await response.json();
      if (successMessage) {
        toast.success(successMessage);
      }
      return responseJSON;
    }
    if (errorMessage) {
      toast.error(errorMessage);
    }
    return null;
  } catch (error) {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }
};
