import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import RichTextEditor, { EditorValue } from 'react-rte';
import { SingleValue } from "react-select";
import { useBrands } from "../admin/brands/useBrands";
import { Button } from "../common/button/button";
import { ZInput } from "../common/input/ZInput";
import { Modal } from "../common/modal/Modal.component";
import { ZSelect } from "../common/select/ZSelect.component";

type AddProductDescriptionProps = {
  onAddProductDescription: Function;
  productDescription: string;
  barcodeNumber:string;
  brand: string;
};

export const AddProductDescription = ({
  onAddProductDescription,
  productDescription,
  barcodeNumber,
  brand,
}: AddProductDescriptionProps) => {
  const [state, setState] = useState<{
    productDescription: EditorValue;
    barcodeNumber: string;
    brand: string;
  }>({ productDescription: RichTextEditor.createValueFromString(productDescription, 'html'), barcodeNumber, brand });

  const { brands } = useBrands();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const toggleModal = () => {
    setModalOpen((prevState) => !prevState);
  };

  const history = useHistory();

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onChangeDescription = (value: EditorValue) => {
    setState({ ...state, productDescription: value });
  };

  const onSelectBrand = (option: SingleValue<{ label: string; value: string }>) => {
    setState({ ...state, brand: option?.value! });
  };

  const goToAddVariations = () => {
    onAddProductDescription({
      productDescription: state.productDescription?.toString?.('html'),
      brand: state.brand,
      barcodeNumber: state.barcodeNumber,
    });
    history.push("/shop/products/add/variations");
  };

  const getBrandOptions = () => brands?.map?.((b) => ({ value: b.id, label: b.name }));

  return (
    <div className="product-details-container">
      <h2 className="text-gray-600 text-lg font-bold font-round ml-4">
        Enter Product Description
      </h2>
      <div className="mt-4">
        <ZInput placeholder="Barcode number" name="barcodeNumber" onChange={onChange} />
      </div>
      <ZSelect placeholder="Product brand" containerClassName="mt-4" onSelect={onSelectBrand} options={getBrandOptions()} />
      <div className="mt-4">
        <Button title="Add product description" onClick={toggleModal} outline />
      </div>
      <div className="fill" />

      <Button
        title="Next"
        onClick={goToAddVariations}
      />

      <Modal className="overflow-hidden" onToggle={toggleModal} isOpen={modalOpen} title="Product description">
        <RichTextEditor rootStyle={{ display: "flex", flexDirection: 'column', flex: 1, minHeight: "50vh" }} value={state.productDescription} onChange={onChangeDescription} />
        <div className="mt-4">
          <Button title="Save" onClick={() => toggleModal()} />
        </div>
      </Modal>
    </div>
  );
};
