/* eslint-disable indent */
import { getAuth, signOut } from "firebase/auth";
import * as actionTypes from "../action.types";
import { ActionInterface } from "../action.types";

export type IUser = {
  shop: string;
  wishList: string[];
  cart: string[];
  _id: string;
  name: string;
  picture: string;
  email: string;
  // eslint-disable-next-line camelcase
  email_verified: boolean;
  token: string;
  tenant: string;
};

const initialState: IUser = {
  shop: "",
  wishList: [],
  cart: [],
  _id: "",
  name: "",
  picture: "",
  email: "",
  email_verified: false,
  token: "",
  tenant: "",
};

export const UserReducer = (
  state = initialState,
  action: ActionInterface,
): IUser => {
  switch (action.type) {
    case actionTypes.API_REGISTER_USER:
      return { ...state, ...action.payload };
    case actionTypes.API_LOGIN_USER:
      return { ...state, ...action.payload };
    case actionTypes.API_LOGOUT_USER:
      const auth = getAuth();
      signOut(auth);
      return initialState;
    default:
      return state;
  }
};
