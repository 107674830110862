/* eslint-disable no-await-in-loop */
import { ref, getStorage, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { v4 as uuid } from "uuid";
import { ProductScreenState } from "../../components/products/addProductScreen";
import { fetchFactory, RequestMethods } from "./fetchFactory";
import * as actionTypes from "../action.types";
import { ActionInterface } from "../action.types";
import { Product } from "../reducers/shop.reducer";

export const addProduct = (item: ProductScreenState) => async (
  dispatch: Dispatch,
  getState: Function,
) => {
  const product = item;
  try {
    const { token } = getState().user;
    const shopId = getState()?.shop?.id;
    const id = await getState().user.id;
    const randomId = uuid();
    const storage = getStorage();
    const imgURLs = [];
    for (let index = 0; index < product.images.length; index++) {
      const image = product.images[index];
      const imageRef = ref(
        storage,
        `shops/${id}/products/${randomId}/${index}.jpg`,
      );
      const upload = await uploadBytes(imageRef, image);
      const downloadUrl = await getDownloadURL(upload.ref);
      imgURLs.push(downloadUrl);
    }
    // remove blobs
    product.images = [];
    product.imageUrls = imgURLs;
    product.shop = shopId;

    await fetchFactory({
      method: RequestMethods.POST,
      endpoint: "/api/products",
      errorMessage: "Failed to create product",
      successMessage: "Created product successfully",
      token,
      body: product,
    });
  } catch (error) {
    toast.error("Failed to upload images");
  }
};

export const getProducts = ({ products = [] }): ActionInterface => ({
  type: actionTypes.API_GET_PRODUCTS,
  payload: products,
});

export const apiGetProducts = (shopId: string) => async (
  dispatch: Dispatch,
  getState: Function,
) => {
  const token = await getState().user.token;
  const response = await fetchFactory({
    method: RequestMethods.GET,
    endpoint: `/api/products/${shopId}`,
    errorMessage: "Failed to get shop details",
    token,
  });
  if (response) {
    dispatch(getProducts({ products: response }));
  }
};

export const apiGetSingleProduct = (
  shopId: string,
  productId: string,
) => async (_dispatch: Dispatch, getState: Function) => {
  const token = await getState().user.token;
  const response = await fetchFactory({
    method: RequestMethods.GET,
    endpoint: `/api/products/${shopId}/${productId}`,
    errorMessage: "Failed to get shop details",
    token,
  });
  if (response) {
    return response;
  }
  return null;
};

// Customers only see products through the search endpoint
export const apiGetProduct = (productId: string) => async () => {
  const response = await fetchFactory({
    method: RequestMethods.GET,
    endpoint: `/api/search/product/${productId}`,
  });

  return response;
};

export const updateProduct = (
  productGroup: string,
  shopId: string,
  update: Partial<Product>,
) => async (dispatch: Function, getState: Function) => {
  const { token } = getState().user;

  const response = await fetchFactory({
    method: RequestMethods.PUT,
    endpoint: `/api/products/${productGroup}`,
    errorMessage: "Failed to update product",
    successMessage: "Updated product successfully",
    token,
    body: update,
  });

  if (response) {
    dispatch(apiGetProducts(shopId));
  }
};
