import React from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { fetchFactory, RequestMethods } from "../../redux/actions/fetchFactory";
import { RootState } from "../../redux/store";
import { ScreenContainer } from "../common/screenContainer";
import { PurchaseHistoryProduct } from "./PurchaseHistory.types";
import { PurchaseHistoryItem } from "./PurchaseHistoryItem";

export const PurchaseHistory = () => {
  const reduxAccountState = useSelector((state: RootState) => state.user);
  const { data, error, isLoading } = useQuery<PurchaseHistoryProduct[]>(
    "purchaseHistoryItems",
    async () =>
      fetchFactory({
        endpoint: `/api/product-orders/user`,
        method: RequestMethods.GET,
        token: reduxAccountState.token,
      }),
  );

  if (error) {
    return <div>Error</div>;
  }

  const renderItems = () =>
    data?.map((item) => <PurchaseHistoryItem item={item} />);

  return (
    <ScreenContainer loading={isLoading}>
      <label className="text-4xl font-bold text-gray-700">
        Purchase History
      </label>
      <div className="w-full flex flex-col">{renderItems()}</div>
    </ScreenContainer>
  );
};
