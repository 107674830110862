import React from "react";
import { Route } from "react-router-dom";
import { AdminNav } from "../common/nav/AdminNav";
import { Brands } from "./brands/Brands";
import { Cities } from "./cities/Cities";
import { Countries } from "./countries/Countries";
import { Towns } from "./towns/Towns";

export const AdminRouter = () => (
  <>
    <AdminNav />
    <Route path="/admin/countries" component={Countries} />
    <Route path="/admin/cities" component={Cities} />
    <Route path="/admin/towns" component={Towns} />
    <Route path="/admin/brands" component={Brands} />
  </>
);
