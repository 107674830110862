import { fetchFactory, RequestMethods } from "./fetchFactory";
import * as actionTypes from "../action.types";

export type ICartItem = {
  quantity: number | string;
  product: string;
};

export const addToCart = (cartItem: ICartItem) => async (
  dispatch: Function,
  getState: Function,
) => {
  const { token } = getState().user;
  await fetchFactory({
    method: RequestMethods.POST,
    endpoint: "/api/cart",
    errorMessage: "Failed to add item to cart",
    successMessage: "Added item to cart successfully",
    token,
    body: cartItem,
  });
};

export const setCart = (cart: ICartItem[]) => ({
  type: actionTypes.API_GET_CART,
  payload: cart,
});

export const apiGetCart = () => async (
  dispatch: Function,
  getState: Function,
) => {
  const { token } = getState().user;
  const response = await fetchFactory({
    method: RequestMethods.GET,
    endpoint: "/api/cart",
    token,
  });

  if (response) {
    dispatch(setCart(response));
  }
};
