import { Dispatch } from "redux";
import * as actionTypes from "../action.types";
import { fetchFactory, RequestMethods } from "./fetchFactory";
import { IShop } from "../reducers/shop.reducer";
import { ActionInterface } from "../action.types";

export const getUserShop = (shopData: IShop): ActionInterface => ({
  type: actionTypes.API_GET_USER_SHOP,
  payload: shopData,
});

export const createShop = (shopData: IShop) => async (
  dispatch: Dispatch,
  getState: Function,
) => {
  const token = await getState().user.token;
  const response = await fetchFactory({
    method: RequestMethods.POST,
    endpoint: "/api/shop",
    body: shopData,
    successMessage: "Created Shop successfully",
    errorMessage: "Failed to create shop",
    token,
  });

  if (response) {
    dispatch(getUserShop(response));
    alert("YES IT WORKED");
  } else {
    alert("We failed to create the shop");
  }
};

export const apiGetShop = () => async (
  dispatch: Dispatch,
  getState: Function,
) => {
  const token = await getState().user.token;

  const response = await fetchFactory({
    method: RequestMethods.GET,
    endpoint: "/api/shop",
    errorMessage: "Failed to get shop details",
    token,
  });
  if (response) {
    dispatch(getUserShop(response));
  }
};
